import axios from "axios";
import React, {useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Loader from "../components/loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../apiConfig';
export default function CreatePayement() {
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    const [name, setName] = useState('')
    const [prenom, setPrenom]= useState('')
    const [date, setDate]= useState('')
    const [libelle, setLibelle]= useState('')
    const [montant, setMontant]= useState('')
    const navigate = useNavigate();
    const [info, setInfo]= useState('')
    const [lapaye, setLapaye]= useState('')
    const [dateControl, setDateControl]= useState('')
    const [loader, setLoader]= useState(false);
    const [secretaire, setSecretaire] = useState([])
    const [gestauto, setGestauto]= useState(Cookies.get('gestauto'))
   
    useEffect(()=>{

        
       
        axios.get(API_BASE_URL +'/createPayement?id='+gestauto+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])

    var date2= new Date();
        
        var autre= date2.toISOString().split('T')[0]  
     
     const [id_ecole]= secretaire.map((data)=>(data.id_ecole));
     const [id_annexe]= secretaire.map((data)=>(data.id_annexe)); 
     useEffect(()=>{
        setDate(autre)
    }, [])

    function handleSubmit(event){
        setInfo('')
        setLapaye('')
        setLoader(true)
        event.preventDefault();
        if( montant=="" || libelle=="" || date=="" || name=="" || prenom==""|| montant<=0 ){
            setLoader(false)
                setInfo("UN CHAMP EST MAL REMPLI VEILLEZ VERIFIER!!!")
            
            

           
         }else if(date>autre){
            setLoader(false)
            setDateControl('Verifier la date')
        }
         else{


            axios.post(API_BASE_URL +'/lapaye', {name,prenom}, { headers})
        .then(res => {
            
            if(res.data.message){
                setLoader(false)
                setLapaye(res.data.message);
             
                
            }
            else{
                const id= res.data[0].id
                const d= res.data[0].montant_payer
               
                const f= d;
              
                axios.post(API_BASE_URL +'/createPayement', {name,prenom,libelle,date,montant,id_ecole,f,id_annexe,id}, { headers})
                .then(res => {
                    setLoader(false)
                    navigate('/payement');
                }).catch(err => console.log(err));
                
            }
        
            
        }).catch(err => console.log(err));
       
         }
    }

    const handleGoBack = () => {
        navigate('/payement');
      };






      const [schoolInfo, setSchoolInfo]= useState([])


const [mie, setMie]= useState(Cookies.get('mie'))

        useEffect(()=>{
            if(secretaire.length>0){   
                axios
                .get(`${API_BASE_URL}/schoolInfo?id=${mie}`, { headers })
                .then((res) => setSchoolInfo(res.data))
                .catch((err) => console.error(err));
            }
         }, [secretaire])
        

  useEffect(()=>{
            if(schoolInfo.length>0){
            const currentDate = new Date();
      const expirationDate = new Date(schoolInfo[0].expiration_date);
      if(expirationDate<currentDate){
      navigate('/payement')
    }
      }
         }, [schoolInfo])









    let load

    if(loader==true){
        load=   <div className="modal">
            <div className="overlay">

            </div>
               <div className="modal-contentis">
    <div className="anous">
    <Loader/>
    </div>
    </div>
        </div>
       
    }else{
        load=''
    }
    return(
        
          <div className="auths"> 
            <body className="body">
         <div className="container">
         <FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
         <h2 className="form-title">Ajout d'une recette</h2>
                 <form onSubmit={handleSubmit} action="#">
                 
                 <div className="main-user-info">
                  <div className="user-input-box">
                  <label htmlFor="fullname" className="">Nom</label>
                    
                    <input type="text" name="fullName" className="" id="fullName" onChange={e => setName(e.target.value)}  ></input>
                   
                    </div> 
                  
                 <div className="user-input-box">
                 <label htmlFor="username" className="">Prenom</label>
                    <input type="text" name="fullName" className="" id="fullName" onChange={e => setPrenom(e.target.value)}></input>
                    
                    </div>   
                    
                    <div className="user-input-box">
                    <label htmlFor="date" className="">DATE</label>
                    <h4 className="text-danger">{dateControl}</h4>
                    <input type="date" value={date} name="date" className=""  id="date" onChange={e => setDate(e.target.value)}  ></input>
                   
                    </div>
                    
                    <div className="user-input-box">
                    <label htmlFor="montant" className="">Montant</label>
                    <input type="number" name="montant" className="" id="montant" onChange={e => setMontant(e.target.value)}></input>
                    
                    </div>
                    <div className="libe">
                    <label htmlFor="libelle" className="">Libelle</label>
                    <input type="text" name="libelle" className="" id="libelle" maxLength={100} onChange={e => setLibelle(e.target.value)}></input>
                 
                    </div>
                    
                    </div>
                    <h3 className="text-danger">{lapaye}</h3><br/>
                    <h4 className="text-danger">{info}</h4>
                    <div className="form-submit-btn">
                    <input type="submit" className='' value="ENREGISTRER" ></input>
                    
                    </div>
                    

                 </form>
            </div> 
            </body>
            {load}
            </div>
    
    )
}