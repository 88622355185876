import Navbar from '../components/navbar';
import React,{ useEffect, useState,useRef} from 'react';
import axios from 'axios';
import Loader from '../components/loader';
import moni from '../components/image/moni.png';
import log from '../components/image/log.png';
import Footer from '../components/footer';
import { Link , useParams,NavLink, useNavigate,  useLocation} from "react-router-dom";
import Cookies from 'js-cookie';
import { sidedataDir } from '../components/sidedataDir'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import moment from "moment";
import logo from '../components/image/logo4.jpg'
import Chart from 'chart.js/auto';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { API_BASE_URL } from '../apiConfig';
import Notification from '../components/notifications';
var date= new Date();
const years= date.getFullYear()
var  month=  date.getMonth()+1
const days= date.getDate()
var datee= new Date(years,month-2,days)
var autre2= datee.toISOString().split('T')[0] 
var autre= date.toISOString().split('T')[0]


export default function Accueil2() {
 

  return(
    <>
    
  
      <SecondDesign />
    

</>
  )
}


const SecondDesign = () => {

  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
    'credentials': 'include',
  };

  //debut first


  const [sidebar, setSidebar]= useState(false)
  const showSidebar = () => setSidebar(!sidebar)
  const [lu,setLu] = useState(Cookies.get('token'))
  const [tok, setTok]= useState('')
  const [titre,setTitre]= useState(Cookies.get('titre'))
  const [vtoken, setVtoken]= useState(Cookies.get('token'))
  const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
const [ce,setCe]= useState(Cookies.get('autoa'))
const [date_connexion,setDate_connexion]=useState('')
const [infoVille,setInfoVille]= useState([])
const [currentAnnexIndex, setCurrentAnnexeIndex]= useState(0)
  const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
  const upTok =()=>{
       setLu(Cookies.get('token'))
   
  }
  const mettreAJourLargeurEcran = () => {
      setLargeurEcran(window.innerWidth);
  
    };


    useEffect(()=>{
      axios.get(API_BASE_URL +'/candidatDir?id='+ce+'',{headers} )
      .then(res =>{ setInfoVille(res.data)

      })
      .catch(err => console.log(err));
  }, [])

    if(tok!= ''){
      if(tok!= lu){
          Cookies.remove('token')
          window.location.reload();
          navigate('/')

      }
  }

  const [old, setOld]= useState('')
  const [new1, setNew1]= useState('')
  const [new2, setNew2]= useState('')
  const [info1, setInfo1]= useState('')
  const [info2, setInfo2]= useState('')
  const[profil, setProfil]= useState(false)

  var navigate= useNavigate()

  useEffect(()=>{
       if(!Cookies.get('token')){
          navigate('/')
       }
  }, [])
  const showProfil = () => setProfil(!profil)
  
  const logout = () =>{
      Cookies.remove('token')
    
      Cookies.remove('autoa')
      Cookies.remove('titre')
      Cookies.remove('gestauto')
      Cookies.remove('default')
      Cookies.remove('default2')
      Cookies.remove('defaultCode')
      Cookies.remove('defaultCode2')
      Cookies.remove('select1')
      Cookies.remove('select2')
      
      window.location.reload();
     navigate('/')
  }
  useEffect(()=>{
      if(!Cookies.get('token')){
         navigate('/')
      }
 }, [])





useEffect(() => {
      
  window.addEventListener('resize', mettreAJourLargeurEcran);

 
  return () => {
    window.removeEventListener('resize', mettreAJourLargeurEcran);
    
  };
 
}, []);



// Recuperer token
useEffect(()=>{
  axios.post(API_BASE_URL +'/setToken',{ gestauto, titre},{headers})
  .then(res => 
      {setTok(res.data[0].token)
        setDate_connexion(res.data[0].date_connexion)

      })
  .catch(err => console.log(err));
}, [])

 
  const [secretaire, setSecretaire] = useState([])
  useEffect(()=>{
      axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'',{headers})
      .then(res => setSecretaire(res.data))
      .catch(err => console.log(err));
  }, [])
  

  var o
  const {id}= useParams();
  if (id){
      o=id
  }else{
      
      o=0
  }




//modification password

const[valid, setValid]= useState('')              
const[valid2, setValid2]= useState('')
const[supMod, setSupMod]= useState(false) 
const toggleSup = ()=> {
  setSupMod(!supMod)
  setValid('')
}

if(valid!= ''){
 toggleSup()
} 


const[supMod2, setSupMod2]= useState(false) 
const toggleSup2 = ()=> {
  setSupMod2(!supMod2)
  setValid2('')
}


if(valid2!= ''){
 toggleSup2()
} 


function handleModf(event){
    const id= gestauto
    event.preventDefault();
    const currentPassword = secretaire.map((data) => data.password)[0];
    
    if(currentPassword===old){
    
        if(new1===new2){
            setInfo2('')
            axios.put(API_BASE_URL +'/upPassword/'+id, {new1,titre}, { headers})
            .then(res => {
                  
        axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
                toggleSup()

            setValid2('on')
            }).catch(err => console.log(err));
        }else{
           
            setInfo2('Incorrecte')
            setTimeout(() => setInfo2(''), 3000)
        }
    }else{
        setInfo1('Incorrecte')
        setTimeout(() => setInfo1(''), 3000)
    }
}


const [menuVisible, setMenuVisible] = useState(false);

const toggleMenu = () => {
    setMenuVisible(!menuVisible);
};



useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuVisible && !document.querySelector('.carD').contains(event.target)) {
            setMenuVisible(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [menuVisible]);


const location = useLocation();



const [showOldPassword, setShowOldPassword] = useState(false);
const [showNewPassword, setShowNewPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);


const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);





const [viewLoader,setViewLoader]= useState(false)




// Détecter si l'utilisateur est sur iOS
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
const [IDV, setIDV]=useState('')



const[photo, setPhoto]= useState('')
              
const[ph, setPh]= useState(false) 

const toggleInscritUser = ()=> {

    setPhoto('')
    setPh(!ph)
 
}


if(photo!== ''){
 setIDV(photo)
 toggleInscritUser()
 
} 






function handleImportClickUser() {
    const fileInput = document.getElementById(`file-input-${IDV}`);
    if (fileInput) {
      fileInput.click();
    } else {
      console.error(`File input with id 'file-input-${IDV}' not found`);
    }
  }
  
  function handleImageUploadUser(event, id) {
  
    const file = event.target.files[0];
    if (file) {
        if(sidebar){
            showSidebar()
        }
        
      setViewLoader(true)
      const formData = new FormData();
      formData.append('image', file);
      formData.append('id', id);
      toggleInscritUser()
      axios.post(API_BASE_URL +'/upload-image-dg', formData)
        .then(response => {
          window.location.reload(); // Recharger la page après l'upload
        })
        .catch(error => {
          console.error("Erreur lors de l'upload de l'image:", error);
        });
    }
  }




  function openCameraUser() {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        video.srcObject = stream;
        video.play();
  
        // Créer une modal personnalisée
        const modal = document.createElement('div');
        modal.className = 'camera-modal-custom';
        document.body.appendChild(modal);
        modal.appendChild(video);
  
        // Bouton pour capturer l'image
        const captureButton = document.createElement('button');
        captureButton.className = 'bouton-camera-capture';
        captureButton.innerHTML = '<i class="fas fa-camera"></i>'; // Icône de capture
        modal.appendChild(captureButton);
  
        // Bouton pour annuler
        const cancelButton = document.createElement('button');
        cancelButton.className = 'bouton-camera-annuler';
        cancelButton.innerText = 'Annuler';
        modal.appendChild(cancelButton);
  
        // Centrer l'icône de la caméra
        video.style.display = 'block';
        video.style.margin = '0 auto';
  
        // Capturer l'image
        captureButton.addEventListener('click', () => {
          canvas.width = 300;
          canvas.height = 300;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
          video.style.display = 'none';
          modal.appendChild(canvas);
  
             
  
          // Bouton pour valider l'image
          const confirmButton = document.createElement('button');
          confirmButton.className = 'bouton-camera-confirmer';
          confirmButton.innerHTML = '<i class="fas fa-check"></i>';
          modal.appendChild(confirmButton);
  
          // Bouton pour reprendre la capture
          const retryButton = document.createElement('button');
          retryButton.className = 'bouton-camera-reprendre';
          retryButton.innerHTML = '<i class="fas fa-redo"></i>';
          modal.appendChild(retryButton);
  
          // Valider et envoyer l'image
          confirmButton.addEventListener('click', () => {
            canvas.toBlob(blob => {
              if (blob) {
                const formData = new FormData();
                formData.append('image', blob, 'profile-image.jpg');
                formData.append('id', IDV);
  toggleInscritUser()
  setViewLoader(true)
                axios.post(API_BASE_URL +'/upload-image-dg', formData)
                  .then(() => {
                        
  
               
                   setViewLoader(false) 
                  window.location.reload()
                  
                  })
                  .catch(error => console.error('Erreur lors de l\'upload:', error));
  
                video.srcObject.getTracks().forEach(track => track.stop());
                modal.remove();
              }
            }, 'image/jpeg');
          });
  
          // Masquer le bouton "Annuler" après capture
          cancelButton.style.display = 'none';
  
          // Reprendre la capture
          retryButton.addEventListener('click', () => {
            confirmButton.remove();
            retryButton.remove();
            canvas.remove();
             
            video.style.display = 'block';
            cancelButton.style.display = 'block'; // Réafficher le bouton "Annuler"
            captureButton.style.display = 'block'; // Faire réapparaître l'icône caméra
          });
  
          captureButton.style.display = 'none'; // Masquer l'icône caméra après capture
        });
  
        cancelButton.addEventListener('click', () => {
          video.srcObject.getTracks().forEach(track => track.stop());
          modal.remove();
        });
      })
      .catch(error => console.error('Erreur lors de l\'accès à la caméra:', error));
  }


let load

if(viewLoader==true){
    load=   <div className="avt">
    <div className="rin">
      <div className="chm">
        <Loader/>
      </div>
    </div>
  </div>
}else{
    load=''
}





const [imgP,setImgp]=useState('')
const [idP, setIdp]= useState('')

useEffect(() => {
    
   if(secretaire.length>0){
  
    setImgp(secretaire[0].image)
    setIdp(secretaire[0].id)
   }
   
  }, [secretaire]);



  function handleImportUserClick2(id) {

    if (isIOS) {
      openCameraUserIOS2(id); // Ouvrir l'appareil photo pour iOS après la confirmation
    } else {
    
      const fileInput = document.getElementById(`file-input-${id}`);
      if (fileInput) {
        fileInput.click(); // Ouvrir le gestionnaire de fichiers pour les autres plateformes
      } else {
        console.error(`File input with id 'file-input-${id}' not found`);
      }
    }

  
  }





  function openCameraUserIOS2(id) {
    if (isIOS) {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*;capture=camera'; // Ouvre directement l'appareil photo sur iOS
      fileInput.style.display = 'none'; // Cacher l'input file visuellement
  
      // Gérer la sélection d'une image via l'appareil photo
      fileInput.onchange = function(event) {
        const file = event.target.files[0];
        if (file) {
          if(photo){
            toggleInscritUser()
           }
       showSidebar()
          setViewLoader(true)

          const formData = new FormData();
          formData.append('image', file, 'profile-image.jpg');
          formData.append('id', id); // Remplacez par votre ID de candidat
  
          axios.post(API_BASE_URL +'/upload-image-dg', formData)
            .then(response => {
              setTimeout(() => {
              
                console.log('Image uploadée avec succès');
                window.location.reload(); // Recharger la page après 3 secondes
              }, 200);
            
            })
            .catch(error => console.error('Erreur lors de l\'upload:', error));
        }
      };
  
      // Ajouter le champ input au document et le déclencher
      document.body.appendChild(fileInput);
      fileInput.click(); // Simuler un clic pour ouvrir la caméra
    }
  }





 //Menu deroulant

 const [isOpen, setIsOpen] = useState(false);

 const [modalActive, setModalActive] = useState(false); // Contrôle l'affichage de la modale
 const handleMouseEnter = () => {
   setIsOpen(true);
 };
 
 const handleMouseLeave = () => {
   setIsOpen(false);
 };
 
 const handleClick = () => {
   if(modalActive === false){
   setIsOpen((prev) => !prev); // Basculer entre ouvert/fermé
 }
 
 };
 
 
 useEffect(()=>{
 if(sidebar===false){
 setIsOpen(false)
 }
 }, [sidebar])
 
 const [theme, setTheme] = useState("Clair"); // Gestion du thème
 const [notifications, setNotifications] = useState(true); // Gestion des notifications
 
 const toggleModalProfil = () => setModalActive(!modalActive);
 
 const [schoolInfo, setSchoolInfo]= useState([])
 
 useEffect(()=>{
 if(modalActive===true){
 setIsOpen(false)
 setSidebar(false)
 }
 }, [modalActive])
 
 useEffect(()=>{
 if(ph=== true){
 setModalActive(false)
 }
 }, [ph])
 
 useEffect(()=>{
    if(secretaire.length>0){   
 axios.get(API_BASE_URL +'/schoolInfoDir?id='+secretaire[0].id_autoa+'', { headers })
 .then(res => setSchoolInfo(res.data))
 .catch(err => console.log(err));
 }
 }, [secretaire])
 












var bar 

if(largeurEcran>= 1200){
  bar= <div className="barHaut">
        <div className="lep"
        onClick={handleClick}
        >
                  
                  
                {/*   <img src={moni} className="user" onClick={showProfil} /> */}


                <img src={imgP ? imgP  : moni} alt="Candidat" className="user" />


{load}


             {ph && ( <>
    <div className="overlay-pop"></div>
    <div className="fenetre-modal">
      <div className="modal-entete">
      
      </div>
      <div className="modal-corps">
      <div className="upload-options">
    {/* Option pour importer une image */}
    <button className="import-btn" onClick={() => handleImportClickUser()}>
      📁 Importer une image
    </button>

    {/* Option pour prendre une photo */}
    <button className="camera-btn" onClick={() => openCameraUser()}>
      📷 Prendre une photo
    </button>
  </div>
      </div>
      <div className="boutons-modal">
        <Link onClick={toggleInscritUser}>
          <button className="bouton-annuler">QUITTER</button>
        </Link>
      </div>
    </div>
  </>)} 

  <input
  type="file"
  id={`file-input-${idP}`}
  style={{ display: 'none' }}
  onChange={(event) => handleImageUploadUser(event, idP)}
/>




                 {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)}
                    
                    <div className="hero"> 
                     <h3 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h3>
                     
                    </div>
       
               {/* menu deroulant navbar */}
               {isOpen && (
                          <div className="menu-deroulant-improbable">
                            <div className="element-menu-improbable" onClick={toggleModalProfil}>
                              <i className="fas fa-user"></i> Profile
                            </div>
                            <div className="element-menu-improbable">
                              <i className="fas fa-cog"></i> Paramètres
                            </div>
                            <div className="element-menu-improbable deconnexion-improbable">
                              <button onClick={logout}>Se déconnecter</button>
                            </div>
                          </div>
                        )}
                  
                  
                  
                  
                  {modalActive && (
                          <div className="modale-profil-unique">
                            <div className="modale-overlay-unique" onClick={toggleModalProfil}></div>
                            <div className="modale-contenu-unique">
                              {/* En-tête */}
                              <div className="modale-entete-unique">
                                <h2>Paramètres du Profil</h2>
                                <button className="bouton-fermer-unique" onClick={toggleModalProfil}>
                                  &times;
                                </button>
                              </div>
                  
                              {/* Corps de la modale */}
                              <div className="modale-corps-unique">
                                {/* Section Informations personnelles */}
                                <div className="section-informations-unique">
                                  <h3>Informations personnelles</h3>
                                  <div className="info-profil-unique">
                                  <div className="image-container8">
                  
                  
                          <PhotoProvider>
                            <PhotoView src={secretaire[0].image ? secretaire[0].image  : moni}>
                            <img src={secretaire[0].image ? secretaire[0].image  : moni}  className="photo-modifiable-unique" />
                                 
                  
                            </PhotoView>
                          </PhotoProvider>
                        
                  
                                  
                  <div className="camera-icon90" onClick={  e => setPhoto(secretaire[0].id) }>
                                📷
                              </div>
                                  </div>
                                   {/*  <div>
                                   Secretaire   {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}
                                     
                                    </div> */}
                  
                  <div className="user-info-unique">
                      <h2>{secretaire[0].nom} {secretaire[0].prenom}</h2>
                      <div className="info-item-unique">
                        <hr className="separator-unique" />
                        <span><strong>Auto-école :</strong><span> {secretaire[0].auto_ecole} </span></span>
                      
                      </div>
                  
                      <div className="info-item-unique">
                        <hr className="separator-unique" />
                        <span>
                    <strong>Statut abonnement :</strong>
                    {schoolInfo.length > 0 ? (
                      // Vérifie si la date d'expiration existe et est valide
                      new Date(schoolInfo[0].expiration_date) > new Date() ? (
                        <span style={{ color: 'green' }}>Actif</span>
                      ) : (
                        <span style={{ color: 'red' }}>Expiré</span>
                      )
                    ) : (
                      <span style={{ color: 'grey' }}>Information non disponible</span>
                    )}
                  </span>
                    
                      </div>
                  
                      <div className="info-item-unique">
                        <hr className="separator-unique" />
                        <span><strong>Date d'expiration :</strong><span>Le &nbsp; {moment(schoolInfo[0].expiration_date).utc(1).format('DD-MM-YYYY')}</span> </span>
                       
                      </div>
                   
                     
                      
                      <div className="info-item-unique">
                        <hr className="separator-unique" />
                        <span>
                    <strong>Dernière activité :</strong> 
                    {date_connexion.length > 0 ? (
                      <>
                        {/* Extraire et formater la date */}
                        <span>{new Date(date_connexion).toLocaleDateString()}
                        &nbsp;à&nbsp;
                        
                          {new Date(date_connexion).toLocaleTimeString()}
                        </span>
                      </>
                    ) : (
                      <span>Information non disponible</span>
                    )}
                  </span>
                        <hr className="separator-unique" />
                      </div>
                    </div>
                                    
                                  </div>
                                </div>
                  
                                {/* Section Sécurité */}
                                <div className="section-securite-unique">
                                  <h3>Sécurité</h3>
                                  <button className="btn-securite-unique" onClick={e => setValid('on')}>Modifier le mot de passe</button>
                                  
                                </div>
                  
                              
                  
                                {/* Section Déconnexion */}
                                <div className="section-deconnexion-unique">
                                  <button className="btn-deconnexion-unique" onClick={logout}>
                                    Se déconnecter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
               {/* fin menu deroulant navbar */}
     
       </div>
  
       <NavLink to={'/accueil2/'+o} style={({isActive})=>{ return{background: isActive? "gray": '#rgb(6, 122, 116)',};} }><button className='pr'> Accueil</button> </NavLink>
      <NavLink to={'/candidatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Candidats</button> </NavLink>
      <NavLink to={'/depenseDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Depenses</button> </NavLink> 
      <NavLink to={'/payementDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Recettes</button> </NavLink>
      <NavLink to={'/bilan/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Bilan Global</button> </NavLink>
      <NavLink to={'/optPresenceDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Presence</button> </NavLink>
      <NavLink to={'/resultatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Resultats</button> </NavLink>
      <NavLink to={'/employe/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Employés</button> </NavLink>
      <Link onClick={logout} className="link">
                             <img src={log} className="am" />
                             <div className="deco">DECONNEXION</div>
                             
                        </Link>  
  </div>   
  }else{
  bar=  <IconContext.Provider value={{color:'#fff'}} >
              
  <div className="icon">
  <div>
  &nbsp;&nbsp;&nbsp;
  <img src={logo} className="userLog"  /> 
  </div>
    
  <div className="naI">
             {secretaire.map((data)=>(data.auto_ecole))} 
           </div>  
  <div className="navbar">
  
  <Link to="#" className={sidebar?  "trois": "menu-bars"}>
     <FaIcons.FaBars onClick={showSidebar} />
  </Link>
  
  &nbsp;&nbsp;&nbsp;
   
  
  </div>
  
 <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
    <ul className="nav-menu-items" >
        <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
               <AiIcons.AiOutlineClose onClick={showSidebar}/>
            </Link>
        </li>
        <div className="carD">
                    <div className="profil">


       {/*  <AiIcons.AiOutlineUser className="icone-profil"   onClick={toggleMenu} /> */}

       {imgP ? (
  <img
    src={imgP}
    alt="Profil"
    className="image-profilUser"
    onClick={handleClick}
  />
) : (
  <AiIcons.AiOutlineUser className="icone-profil" onClick={handleClick} />
)}


<input
  type="file"
  id={`file-input-${idP}`}
  style={{ display: 'none' }}
  onChange={(event) => handleImageUploadUser(event, idP)}
/>


        <div className="infos-utilisateur">
           <p className="inPo"   onClick={handleClick}> {secretaire.map((data)=>(data.poste))} </p>
          <p className="inN"  onClick={handleClick}> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
        </div>
      </div>


                    {/* menu deroulant mobile */}

  
                    {isOpen && (
        <div className="menu-deroulant-improbable">
          <div className="element-menu-improbable" onClick={toggleModalProfil}>
            <i className="fas fa-user"></i> Profile
          </div>
          <div className="element-menu-improbable">
            <i className="fas fa-cog"></i> Paramètres
          </div>
          <div className="element-menu-improbable deconnexion-improbable">
            <button onClick={logout}>Se déconnecter</button>
          </div>
        </div>
      )}




{modalActive && (
        <div className="modale-profil-unique">
          <div className="modale-overlay-unique" onClick={toggleModalProfil}></div>
          <div className="modale-contenu-unique">
            {/* En-tête */}
            <div className="modale-entete-unique">
              <h2>Paramètres du Profil</h2>
              <button className="bouton-fermer-unique" onClick={toggleModalProfil}>
                &times;
              </button>
            </div>

            {/* Corps de la modale */}
            <div className="modale-corps-unique">
              {/* Section Informations personnelles */}
              <div className="section-informations-unique">
               
                <div className="info-profil-unique">
                <div className="image-container8">

                <PhotoProvider>
          <PhotoView src={secretaire[0].image ? secretaire[0].image  : moni}>
          <img src={secretaire[0].image ? secretaire[0].image  : moni}  className="photo-modifiable-unique" />
               

          </PhotoView>
        </PhotoProvider>


                <div className="camera-icon90" onClick={ () => isIOS ? handleImportUserClick2(secretaire[0].id) : handleImportUserClick2(secretaire[0].id)  }>
              📷
            </div>
                </div>
                <h2>{secretaire[0].nom} {secretaire[0].prenom}</h2>
                 {/*  <div>
                 Secretaire   {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}
                   
                  </div> */}

<div className="user-info-unique">
    
    <div className="info-item-unique">
      <hr className="separator-unique" />
      <span><strong>Auto-école :</strong><span> {secretaire[0].auto_ecole} </span></span>
    
    </div>

    <div className="info-item-unique">
      <hr className="separator-unique" />
      <span>
  <strong>Statut abonnement :</strong>
  {schoolInfo.length > 0 ? (
    // Vérifie si la date d'expiration existe et est valide
    new Date(schoolInfo[0].expiration_date) > new Date() ? (
      <span style={{ color: 'green' }}>Actif</span>
    ) : (
      <span style={{ color: 'red' }}>Expiré</span>
    )
  ) : (
    <span style={{ color: 'grey' }}>Information non disponible</span>
  )}
</span>
  
    </div>

    <div className="info-item-unique">
      <hr className="separator-unique" />
      <span><strong>Date d'expiration :</strong><span>Le &nbsp; {moment(schoolInfo[0].expiration_date).utc(1).format('DD-MM-YYYY')}</span> </span>
     
    </div>
 
   
    
    <div className="info-item-unique">
      <hr className="separator-unique" />
      <span>
  <strong>Dernière activité :</strong> 
  {date_connexion.length > 0 ? (
    <>
      {/* Extraire et formater la date */}
      <span>{new Date(date_connexion).toLocaleDateString()}
      &nbsp;à&nbsp;
      
        {new Date(date_connexion).toLocaleTimeString()}
      </span>
    </>
  ) : (
    <span>Information non disponible</span>
  )}
</span>
      <hr className="separator-unique" />
    </div>
  </div>
                  
                </div>
              </div>

              {/* Section Sécurité */}
              <div className="section-securite-unique">
                <h3>Sécurité</h3>
                <button className="btn-securite-unique" onClick={e => setValid('on')}>Modifier le mot de passe</button>
                
              </div>

            

              {/* Section Déconnexion */}
              <div className="section-deconnexion-unique">
                <button className="btn-deconnexion-unique" onClick={logout}>
                  Se déconnecter
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


    {/* Fin menu deroulant mobile */}


      </div>
        {sidedataDir.map((item, index) =>{
         const isActive = location.pathname.startsWith(item.path);
          return (
           
            <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
                             <Link to={item.path+'/'+o}>
                    <span>{item.title}</span>
                 </Link>
                
            </li>
            
          
          )
 
        })}
       <li className="nav-text">
        <Link onClick={logout}  >
             
               <span>Deconnexion</span>
               
          </Link>
          </li> 
    </ul>
 </nav>
  </div>
  {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)} 



  </IconContext.Provider>
      
  }
  //end first

// ma logique
const handleNextAnnex=()=>{
  setCurrentAnnexeIndex((prevIndex)=> Math.min(prevIndex+1,infoVille.length-1));
};

const handlePrevAnnex =()=>{
  setCurrentAnnexeIndex((prevIndex)=> Math.max(prevIndex-1,0));
};
// fin de ma logique












   const [showNotification, setShowNotification] = useState(false);



  useEffect(()=>{
      if(schoolInfo.length>0){

       
          const currentDate = new Date();
          const expirationDate = new Date(schoolInfo[0].expiration_date);
         
      if ( expirationDate < currentDate) {
      
        setShowNotification(true); // Affiche le pop-up
        setTimeout(() => setShowNotification(false), 5000); // Cache le pop-up après 5 secondes
      }
    }
      }, [schoolInfo])








  return (
    <>
     
     {bar}
  
    <div className='wax'>

    <Notification
        description="Votre abonnement a expiré. Veuillez le renouveler pour accéder à toutes les fonctionnalités."
        show={showNotification}
      />


<div>

  {infoVille.length>0 &&(
    <Data id={infoVille[currentAnnexIndex].id_ecole}  currentAnnexIndex={currentAnnexIndex} setCurrentAnnexeIndex={setCurrentAnnexeIndex}     infoVille={infoVille}/>
  )}
 
</div>

      </div>
      <Footer/>
      </>
  )

}




function Data ({id, currentAnnexIndex, infoVille, setCurrentAnnexeIndex}){


  const [select1,setSelect1]= useState(Cookies.get('select1'))
  const [select2,setSelect2]= useState(Cookies.get('select2'))


  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
    'credentials': 'include',
  };
  
  const [date1, setDate1]= useState('')
  const [date2, setDate2]= useState('')
  const [nom, setNom]= useState('')
  const [lieu, setLieu]= useState('')


  useEffect(()=>{
   if( select1 === undefined || select2== undefined){ 
   setDate1(autre2)
   setDate2(autre)
  }else if (select1 || select2){
    setDate1(select1)
    setDate2(select2)
  }
 }, [])




 

 const handleNextAnnex = () => {
  setCurrentAnnexeIndex((prevIndex) => Math.min(prevIndex + 1, infoVille.length - 1));
};

const handlePrevAnnex = () => {
  setCurrentAnnexeIndex((prevIndex) => Math.max(prevIndex - 1, 0));
};



const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);

const mettreAJourLargeurEcran = () => {
    setLargeurEcran(window.innerWidth);

  };





  useEffect(() => {
      
    window.addEventListener('resize', mettreAJourLargeurEcran);
  
   
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    };
   
  }, []);


  const [minStartDate, setMinStartDate] = useState('');
  const [maxStartDate, setMaxStartDate] = useState('');
  const [minEndDate, setMinEndDate] = useState('');
  const [maxEndDate, setMaxEndDate] = useState('');





  const calculateMaxEndDate = (startDate, monthInterval) => {
    const start = new Date(startDate);
    const maxDate = new Date(start.setMonth(start.getMonth() + monthInterval));
    return maxDate.toISOString().split('T')[0];
  };

  const calculateMinEndDate = (startDate, monthInterval) => {
    const start = new Date(startDate);
    const minDate = new Date(start.setMonth(start.getMonth() + monthInterval));
    return minDate.toISOString().split('T')[0];
  };

  const calculateMaxStartDate = (endDate, monthInterval) => {
    const end = new Date(endDate);
    const maxDate = new Date(end.setMonth(end.getMonth() - monthInterval));
    return maxDate.toISOString().split('T')[0];
  };

  const calculateMinStartDate = (endDate, monthInterval) => {
    const end = new Date(endDate);
    const minDate = new Date(end.setMonth(end.getMonth() - monthInterval));
    return minDate.toISOString().split('T')[0];
  };


  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    setDate1(selectedStartDate);

    // Limiter la date de fin à un intervalle de 1 à 4 mois après la date de début
    const minDate = calculateMinEndDate(selectedStartDate, 1); // +1 mois
    const maxDate = calculateMaxEndDate(selectedStartDate, 4); // +4 mois

    setMinEndDate(minDate);
    setMaxEndDate(maxDate);

    // Réinitialiser la date de fin si elle n'est pas dans l'intervalle
    if (date2 && (date2 < minDate || date2 > maxDate)) {
      setDate2('');
    }
  };



  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    setDate2(selectedEndDate);

    // Limiter la date de début à un intervalle de 1 à 4 mois avant la date de fin
    const minDate = calculateMinStartDate(selectedEndDate, 4); // -4 mois
    const maxDate = calculateMaxStartDate(selectedEndDate, 1); // -1 mois

    setMinStartDate(minDate);
    setMaxStartDate(maxDate);

    // Réinitialiser la date de début si elle n'est pas dans l'intervalle
    if (date1 && (date1 < minDate || date1 > maxDate)) {
      setDate1('');
    }
  };



let lig
let input1
let input2

  if(largeurEcran>= 1200){
lig=<br/>
input1= <input type='date' value={date1} className='oixi' onChange={e => setDate1(e.target.value)}></input>
input2= <input type='date' value={date2}  className='oixi' onChange={e => setDate2(e.target.value)} ></input>
  }else{
    lig=''
    input1 = 
        <input type='date' value={date1} className='oixi' onChange={handleStartDateChange}  max={new Date().toISOString().split('T')[0]} ></input>
    input2=    <input type='date' value={date2}  className='oixi' onChange={handleEndDateChange}    min={minEndDate || ''} max={maxEndDate} disabled={!date1 && !date2}></input> 
    
    
    
  }

 useEffect(()=>{
  axios.post(API_BASE_URL +'/schoolName',{id},{headers})
  .then(res => 
      {
 setNom(res.data[0].nom)
 setLieu(res.data[0].ville)
      })
  .catch(err => console.log(err));
}, [id])

const intervalData = [];
let currentDate = new Date(date1);

while (currentDate <= new Date(date2)) {
  const month = currentDate.toLocaleString('default', { month: 'long' }); // Obtenez le nom complet du mois

  
  // Déterminez la date de début du mois
  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const startDateFormatted = startOfMonth.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });

  // Déterminez la date de fin du mois
  const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const endDateFormatted = endOfMonth.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });

  // Ajoutez les dates de début et de fin à intervalData
  intervalData.push({ month: month, startDate: startDateFormatted, endDate: endDateFormatted });

 
  console.log('intervalle data ici',intervalData)
  
  
  // Passez au mois suivant
  currentDate.setMonth(currentDate.getMonth() + 1);
}
const [labels, setLabels]= useState('')
const [depense, setDepense]= useState('')
const [depenseTotal, setDepenseTotal]= useState('')
const [paiement, setPaiement]= useState('')
const [paiementTotal, setPaiementTotal]= useState('')
const [inscrit, setInscrit]= useState([])
const [inscritTotal, setInscritTotal]= useState([])
const [permis, setPermis]= useState('')
const [stop, setStop] =useState('')
const [nbrAd, setNbrAd]= useState('')
const [nbrNad, setNbrNad]= useState('')
const [stop2, setStop2] =useState('')

const [depDir,setDepDir]= useState([])











// default stat card

 useEffect(()=> {
  axios.post(API_BASE_URL +'/countDash', {date1,date2,id},{headers})
  .then(res =>{ 
    console.log('dash',res.data);
   
    const counts = res.data.map(item => item.count); // Extraction des valeurs 'count' uniquement
    console.log('Counts:', counts);
    setInscritTotal(counts.reduce((acc, curr)=> acc+curr,0))
    setInscrit(counts);
    setStop('oui')
  }
  ).catch(err => console.log(err));


  axios.post(API_BASE_URL +'/nbrAd', {date1,date2,id},{headers})
  .then(res =>{ 
    setNbrAd(res.data[0].count)
  }
  ).catch(err => console.log(err));

  
  axios.post(API_BASE_URL +'/nbrNad', {date1,date2,id},{headers})
  .then(res =>{ 
    setNbrNad(res.data[0].count)
  }
  ).catch(err => console.log(err));

  
  axios.post(API_BASE_URL +'/PermisRetire', {date1,date2,id},{headers})
  .then(res =>{ 
    setPermis(res.data[0].count)
  }
  ).catch(err => console.log(err));

},[id]);



useEffect(()=> {
  axios.post(API_BASE_URL +'/countPaie', {date1,date2,id},{headers})
  .then(res =>{ 
    console.log('paie',res.data);
    const counts = res.data.map(item => item.count); // Extraction des valeurs 'count' uniquement
    console.log('CountsPaie:', counts);
    setPaiementTotal(counts.reduce((acc, curr)=> acc+curr,0))
    setPaiement(counts);
      setStop2('oui') 
  }
   
  ).catch(err => console.log(err));


  axios.post(API_BASE_URL +'/countDepe', {date1,date2,id},{headers})
  .then(res =>{ 
    console.log('depe',res.data);
    const counts = res.data.map(item => item.count); // Extraction des valeurs 'count' uniquement
    console.log('CountsDepe:', counts);
    setDepenseTotal(counts.reduce((acc, curr)=> acc+curr,0))
    setDepense(counts);
      setStop2('oui') 
  }
   
  ).catch(err => console.log(err));


  axios.post(API_BASE_URL +'/specialDep', {date1,date2,id}, { headers})
  .then(res => {
    if (Array.isArray(res.data)) {
      setDepDir(res.data);  // Inverse l'ordre des éléments si res.data est un tableau
    } else {
      console.error("Les données reçues ne sont pas un tableau : ", res.data);
      setDepDir([]);  // Définit un tableau vide si ce n'est pas un tableau
    }
  

      
  }).catch(err => {
      console.log(err)
 
  });

},[id]);

// end default stat cart

//begin select date stat

if(date1!=='' && date2!=='' && stop2===''&& id){

  axios.post(API_BASE_URL +'/countPaie', {date1,date2,id},{headers})
  .then(res =>{ 
    console.log('paie',res.data);
    const counts = res.data.map(item => item.count); // Extraction des valeurs 'count' uniquement
    console.log('CountsPaie:', counts);
    setPaiementTotal(counts.reduce((acc, curr)=> acc+curr,0))
    setPaiement(counts);
      setStop2('oui') 
  }
   
  ).catch(err => console.log(err));


  axios.post(API_BASE_URL +'/countDepe', {date1,date2,id},{headers})
  .then(res =>{ 
    console.log('depe',res.data);
    const counts = res.data.map(item => item.count); // Extraction des valeurs 'count' uniquement
    console.log('CountsDepe:', counts);
    setDepenseTotal(counts.reduce((acc, curr)=> acc+curr,0))
    setDepense(counts);
      setStop2('oui') 
  }
   
  ).catch(err => console.log(err));


  // de l'autre

  axios.post(API_BASE_URL +'/countDash', {date1,date2,id},{headers})
  .then(res =>{ 
    console.log('dash',res.data);
   
    const counts = res.data.map(item => item.count); // Extraction des valeurs 'count' uniquement
    console.log('Counts:', counts);
    setInscritTotal(counts.reduce((acc, curr)=> acc+curr,0))
    setInscrit(counts);
    setStop('oui')
  }
   

  ).catch(err => console.log(err));


  axios.post(API_BASE_URL +'/nbrAd', {date1,date2,id},{headers})
  .then(res =>{ 
    setNbrAd(res.data[0].count)
  }
   

  ).catch(err => console.log(err));

  
  axios.post(API_BASE_URL +'/nbrNad', {date1,date2,id},{headers})
  .then(res =>{ 
    setNbrNad(res.data[0].count)
  }
   

  ).catch(err => console.log(err));

  
  axios.post(API_BASE_URL +'/PermisRetire', {date1,date2,id},{headers})
  .then(res =>{ 
    setPermis(res.data[0].count)
  }
   

  ).catch(err => console.log(err));


  
  axios.post(API_BASE_URL +'/specialDep', {date1,date2,id}, { headers})
  .then(res => {
  
     if (Array.isArray(res.data)) {
      setDepDir(res.data);  // Inverse l'ordre des éléments si res.data est un tableau
    } else {
      console.error("Les données reçues ne sont pas un tableau : ", res.data);
      setDepDir([]);  // Définit un tableau vide si ce n'est pas un tableau
    }
      
  }).catch(err => {
      console.log(err)
 
  });



}

// dash code

const chartRef = useRef();
 let myChart = null;

 useEffect(() => {
   const ctx = chartRef.current.getContext('2d');

   // Exemple de données (à remplacer par vos propres données)
   
// Utilisez les données de l'intervalle pour générer les labels et les données du graphique
   setLabels( intervalData.map(monthData => monthData.month)); // Supposons que monthData soit un tableau d'objets avec les propriétés month, startDate et endDate
    const data = intervalData.map(monthData => monthData.value); // Supposons que value est la valeur de la statistique pour chaque mois
    



    
    // Mettez à jour l'état du graphique avec les nouvelles données
    const chartData=({
      labels: labels,
      datasets: [
        {
          label: "Nombre de candidat inscrits",
          data:inscrit,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
          barThickness: 30, // Largeur fixe pour chaque barre
          maxBarThickness: 40, // Largeur maximale
          minBarLength: 10, // Longueur minimale
        }
      ]
    });
  

   const options = {
     scales: {
       y: {
        grid: {
          display: false, // Désactive la grille de l'axe X
      },
         beginAtZero: true,
         title: {
           display: true,
           text: "Nombre d'inscrits",
           color: '#000', // Couleur du titre
           font: {
             family: 'Arial',
             size: 14,
             weight: 'bold',
             lineHeight: 1.2
           },
           padding: { top: 10, left: 0, right: 0, bottom: 0 }
         }
       },
       x: {
        grid: {
          display: false, // Désactive la grille de l'axe X
      },
         title: {
           display: true,
           text: 'Mois',
           color: '#000', // Couleur du titre
           font: {
             family: 'Arial',
             size: 14,
             weight: 'bold',
             lineHeight: 1.2
           },
           padding: { top: 10, left: 0, right: 0, bottom: 0 }
         }
       }
     },
     grid: {
      display: true,  // Afficher ou masquer la grille
      color: 'rgba(0, 0, 0, 0.1)', // Couleur des lignes de la grille
      lineWidth: 1, // Épaisseur des lignes
      drawBorder: true, // Dessiner les bords de l'axe
      borderDash: [5, 5], // Type de ligne (pointillée ici)
      zeroLineColor: '#000', // Couleur de la ligne zéro
      zeroLineWidth: 2, // Épaisseur de la ligne zéro
    },
    
    ticks: {
      display: true,  // Afficher ou masquer les étiquettes
      color: '#000', // Couleur des étiquettes
      font: {
        family: 'Arial',
        size: 12,
        weight: 'normal',
      },
      padding: 5, // Espace entre les étiquettes et l'axe
      stepSize: 10, // Intervalle entre les valeurs
      callback: function(value, index, values) {
        return value + ' €'; // Ajout d'un suffixe aux valeurs
      }
    },

    position: 'bottom',

    stacked: true,

    border: {
      display: true, // Afficher ou masquer la bordure
      color: '#000', // Couleur de la bordure
      width: 2 // Épaisseur de la bordure
    },
    responsive: true,

maintainAspectRatio: false,

plugins: {
  legend: {
    display: true, // Afficher ou masquer la légende
    position: 'top', // Position de la légende ('top', 'left', 'bottom', 'right')
    labels: {
      color: '#000', // Couleur du texte des légendes
      font: {
        family: 'Arial',
        size: 12,
        weight: 'bold',
      },
      boxWidth: 20, // Taille de la boîte de couleur à côté du texte
      padding: 10 // Espace entre les éléments de la légende
    }
  }
},

plugins: {
  tooltip: {
    enabled: true, // Afficher ou masquer les tooltips
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Couleur de fond
    titleFont: {
      family: 'Arial',
      size: 14,
      weight: 'bold',
    },
    bodyFont: {
      family: 'Arial',
      size: 12,
    },
    footerFont: {
      family: 'Arial',
      size: 10,
    },
    padding: 10, // Espace à l'intérieur du tooltip
    cornerRadius: 4, // Bord arrondi du tooltip
  }
},

animation: {
  duration: 1000, // Durée de l'animation en ms
  easing: 'easeInOutQuad', // Type d'animation
},

layout: {
  padding: {
    top: 10,
    right: 10,
    bottom: 10,
    left: 10
  }
},

backgroundColor: 'rgba(255, 255, 255, 1)',

elements: {
  bar: {
    backgroundColor: '#8884d8', // Couleur des barres
    borderRadius: 4, // Rayon des coins des barres
    borderWidth: 2, // Épaisseur des bordures des barres
  }
},

   };

   if (myChart) {
     myChart.destroy(); // Détruire le graphique existant si présent
   }

   myChart = new Chart(ctx, {
     type: 'bar',
     data: chartData,
     options: options
   });

   return () => {
     if (myChart) {
       myChart.destroy(); // Détruire le graphique lors du démontage du composant
     }
   };
 }, [inscrit]);



 const chartRef2 = useRef();
 let myChart2 = null;


 useEffect(() => {
  const ctx = chartRef2.current.getContext('2d');

  // Exemple de données (à remplacer par vos propres données)
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Revenus',
        data: paiement, // Remplacez ces données par vos propres données
        backgroundColor: '#007bff',
        borderColor: '#0056b3',
        borderWidth: 1,
        barThickness: 30, // Largeur fixe pour chaque barre
        maxBarThickness: 40, // Largeur maximale
        minBarLength: 10, // Longueur minimale
      },
      {
        label: 'Dépenses',
        data: depense, // Remplacez ces données par vos propres données
        backgroundColor: '#ffc107',
        borderColor: '#ffca28',
        borderWidth: 1,
        barThickness: 30, // Largeur fixe pour chaque barre
        maxBarThickness: 40, // Largeur maximale
        minBarLength: 10, // Longueur minimale
      }
    ]
  };

  const options = {
    scales: {
      y: {
        grid: {
          display: false, // Désactive la grille de l'axe X
      },
        beginAtZero: true,
        title: {
          display: true,
          text: 'Montant (en FCFA)',
          color: '#000', // Couleur du titre
          font: {
            family: 'Arial',
            size: 14,
            weight: 'bold',
            lineHeight: 1.2
          },
          padding: { top: 10, left: 0, right: 0, bottom: 0 }
        }
      },
      x: {
        grid: {
          display: false, // Désactive la grille de l'axe X
      },
        title: {
          display: true,
          text: 'Mois',
          color: '#000', // Couleur du titre
          font: {
            family: 'Arial',
            size: 14,
            weight: 'bold',
            lineHeight: 1.2
          },
          padding: { top: 10, left: 0, right: 0, bottom: 0 }
        }
      }
    },
    grid: {
      display: true,  // Afficher ou masquer la grille
      color: 'rgba(0, 0, 0, 0.1)', // Couleur des lignes de la grille
      lineWidth: 1, // Épaisseur des lignes
      drawBorder: true, // Dessiner les bords de l'axe
      borderDash: [5, 5], // Type de ligne (pointillée ici)
      zeroLineColor: '#000', // Couleur de la ligne zéro
      zeroLineWidth: 2, // Épaisseur de la ligne zéro
    },
    
    ticks: {
      display: true,  // Afficher ou masquer les étiquettes
      color: '#000', // Couleur des étiquettes
      font: {
        family: 'Arial',
        size: 12,
        weight: 'normal',
      },
      padding: 5, // Espace entre les étiquettes et l'axe
      stepSize: 10, // Intervalle entre les valeurs
      callback: function(value, index, values) {
        return value + ' €'; // Ajout d'un suffixe aux valeurs
      }
    },

    position: 'bottom',

    stacked: true,

    border: {
      display: true, // Afficher ou masquer la bordure
      color: '#000', // Couleur de la bordure
      width: 2 // Épaisseur de la bordure
    },
    responsive: true,

maintainAspectRatio: false,

plugins: {
  legend: {
    display: true, // Afficher ou masquer la légende
    position: 'top', // Position de la légende ('top', 'left', 'bottom', 'right')
    labels: {
      color: '#000', // Couleur du texte des légendes
      font: {
        family: 'Arial',
        size: 12,
        weight: 'bold',
      },
      boxWidth: 20, // Taille de la boîte de couleur à côté du texte
      padding: 10 // Espace entre les éléments de la légende
    }
  }
},

plugins: {
  tooltip: {
    enabled: true, // Afficher ou masquer les tooltips
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Couleur de fond
    titleFont: {
      family: 'Arial',
      size: 14,
      weight: 'bold',
    },
    bodyFont: {
      family: 'Arial',
      size: 12,
    },
    footerFont: {
      family: 'Arial',
      size: 10,
    },
    padding: 10, // Espace à l'intérieur du tooltip
    cornerRadius: 4, // Bord arrondi du tooltip
  }
},

animation: {
  duration: 1000, // Durée de l'animation en ms
  easing: 'easeInOutQuad', // Type d'animation
},

layout: {
  padding: {
    top: 10,
    right: 10,
    bottom: 10,
    left: 10
  }
},

backgroundColor: 'rgba(255, 255, 255, 1)',

elements: {
  bar: {
    backgroundColor: '#8884d8', // Couleur des barres
    borderRadius: 4, // Rayon des coins des barres
    borderWidth: 2, // Épaisseur des bordures des barres
  }
},

  };

  if (myChart2) {
    myChart2.destroy(); // Détruire le graphique existant si présent
  }

  myChart2 = new Chart(ctx, {
    type: 'bar',
    data: data,
    options: options
  });

  return () => {
    if (myChart2) {
      myChart2.destroy(); // Détruire le graphique lors du démontage du composant
    }
  };
}, [paiement,labels,depense]);
//end






 
  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = (event) => {
    event.preventDefault();

    Cookies.set('select1',date1)
    Cookies.set('select2',date2)

    // Exécuter la requête Axios pour récupérer les données actualisées en fonction des nouvelles dates
    axios.post(API_BASE_URL +'/countDash', { date1, date2,id },{headers})
      .then(res => {
        console.log('Nouvelles données:', res.data);
        const counts = res.data.map(item => item.count); // Extraction des valeurs 'count' uniquement
        console.log('Counts:', counts);
        setInscritTotal(counts.reduce((acc, curr)=> acc+curr,0))
        setInscrit(counts);
        setStop('oui');
      })
      .catch(err => console.log(err));

      axios.post(API_BASE_URL +'/countPaie', {date1,date2,id},{headers})
      .then(res =>{ 
        console.log('paie',res.data);
        const counts = res.data.map(item => item.count); // Extraction des valeurs 'count' uniquement
        console.log('CountsPaie:', counts);
        setPaiementTotal(counts.reduce((acc, curr)=> acc+curr,0))
        setPaiement(counts);
          setStop2('oui') 
      }
       
      ).catch(err => console.log(err));

      
  axios.post(API_BASE_URL +'/countDepe', {date1,date2,id},{headers})
  .then(res =>{ 
    console.log('depe',res.data);
    const counts = res.data.map(item => item.count); // Extraction des valeurs 'count' uniquement
    console.log('CountsDepe:', counts);
    setDepenseTotal(counts.reduce((acc, curr)=> acc+curr,0))
    setDepense(counts);
      setStop2('oui') 
  }
   
  ).catch(err => console.log(err));

  axios.post(API_BASE_URL +'/nbrAd', {date1,date2,id},{headers})
  .then(res =>{ 
    setNbrAd(res.data[0].count)
  }
   
  ).catch(err => console.log(err));

  
  axios.post(API_BASE_URL +'/nbrNad', {date1,date2,id},{headers})
  .then(res =>{ 
    setNbrNad(res.data[0].count)
  }
   

  ).catch(err => console.log(err));

  
  axios.post(API_BASE_URL +'/PermisRetire', {date1,date2,id},{headers})
  .then(res =>{ 
    setPermis(res.data[0].count)
  }
   

  ).catch(err => console.log(err));

  
  axios.post(API_BASE_URL +'/specialDep', {date1,date2,id}, { headers})
  .then(res => {
    if (Array.isArray(res.data)) {
      setDepDir(res.data);  // Inverse l'ordre des éléments si res.data est un tableau
    } else {
      console.error("Les données reçues ne sont pas un tableau : ", res.data);
      setDepDir([]);  // Définit un tableau vide si ce n'est pas un tableau
    }
      
  }).catch(err => {
      console.log(err)
 
  });

  };

  useEffect(() => {
    // Code pour initialiser le graphique au chargement du composant
    const ctx = chartRef.current.getContext('2d');
    const options = {
      scales: {
        y: {
          grid: {
            display: false, // Désactive la grille de l'axe X
        },
          beginAtZero: true,
          title: {
            display: true,
            text: "Nombre d'inscrits",
            color: '#000', // Couleur du titre
            font: {
              family: 'Arial',
              size: 14,
              weight: 'bold',
              lineHeight: 1.2
            },
            padding: { top: 10, left: 0, right: 0, bottom: 0 }
          }
        },
        x: {
          grid: {
            display: false, // Désactive la grille de l'axe X
        },
          title: {
            display: true,
            text: 'Mois',
            color: '#000', // Couleur du titre
            font: {
              family: 'Arial',
              size: 14,
              weight: 'bold',
              lineHeight: 1.2
            },
            padding: { top: 10, left: 0, right: 0, bottom: 0 }
          }
        }
      },
      grid: {
        display: true,  // Afficher ou masquer la grille
        color: 'rgba(0, 0, 0, 0.1)', // Couleur des lignes de la grille
        lineWidth: 1, // Épaisseur des lignes
        drawBorder: true, // Dessiner les bords de l'axe
        borderDash: [5, 5], // Type de ligne (pointillée ici)
        zeroLineColor: '#000', // Couleur de la ligne zéro
        zeroLineWidth: 2, // Épaisseur de la ligne zéro
      },
      
      ticks: {
        display: true,  // Afficher ou masquer les étiquettes
        color: '#000', // Couleur des étiquettes
        font: {
          family: 'Arial',
          size: 12,
          weight: 'normal',
        },
        padding: 5, // Espace entre les étiquettes et l'axe
        stepSize: 10, // Intervalle entre les valeurs
        callback: function(value, index, values) {
          return value + ' €'; // Ajout d'un suffixe aux valeurs
        }
      },
  
      position: 'bottom',
  
      stacked: true,
  
      border: {
        display: true, // Afficher ou masquer la bordure
        color: '#000', // Couleur de la bordure
        width: 2 // Épaisseur de la bordure
      },
      responsive: true,
  
  maintainAspectRatio: false,
  
  plugins: {
    legend: {
      display: true, // Afficher ou masquer la légende
      position: 'top', // Position de la légende ('top', 'left', 'bottom', 'right')
      labels: {
        color: '#000', // Couleur du texte des légendes
        font: {
          family: 'Arial',
          size: 12,
          weight: 'bold',
        },
        boxWidth: 20, // Taille de la boîte de couleur à côté du texte
        padding: 10 // Espace entre les éléments de la légende
      }
    }
  },
  
  plugins: {
    tooltip: {
      enabled: true, // Afficher ou masquer les tooltips
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // Couleur de fond
      titleFont: {
        family: 'Arial',
        size: 14,
        weight: 'bold',
      },
      bodyFont: {
        family: 'Arial',
        size: 12,
      },
      footerFont: {
        family: 'Arial',
        size: 10,
      },
      padding: 10, // Espace à l'intérieur du tooltip
      cornerRadius: 4, // Bord arrondi du tooltip
    }
  },
  
  animation: {
    duration: 1000, // Durée de l'animation en ms
    easing: 'easeInOutQuad', // Type d'animation
  },
  
  layout: {
    padding: {
      top: 10,
      right: 10,
      bottom: 10,
      left: 10
    }
  },
  
  backgroundColor: 'rgba(255, 255, 255, 1)',
  
  elements: {
    bar: {
      backgroundColor: '#8884d8', // Couleur des barres
      borderRadius: 4, // Rayon des coins des barres
      borderWidth: 2, // Épaisseur des bordures des barres
    }
  },
  
    };

    if (myChart) {
      myChart.destroy(); // Détruire le graphique existant si présent
    }

    myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: "Nombre de candidat inscrits",
            data: inscrit,
            backgroundColor: '#28a745',
            borderColor: '#1e7e34',
            borderWidth: 1,
            barThickness: 30, // Largeur fixe pour chaque barre
            maxBarThickness: 40, // Largeur maximale
            minBarLength: 10, // Longueur minimale
          }
        ]
      },
      options: options
    });

    return () => {
      if (myChart) {
        myChart.destroy(); // Détruire le graphique lors du démontage du composant
      }
    };
  }, [inscrit, labels]); // Assurez-vous d'inclure labels dans les dépendances de useEffect

  var o
  const {id2}= useParams();
  if (id2){
      o=id2
  }else{
      
      o=0
  }


  let totalDep1=0

  depDir.map((data, i)=> (
    totalDep1=totalDep1+data.montant
))
  
Cookies.set('olaf', id, { expires: 7 })




const [abonne, setAbonne] = useState(null);
const [message, setMessage] = useState('');


useEffect(() => {
  axios.post(API_BASE_URL +'/abonne', { id }, { headers })
      .then(res => {
          const expirationDate = res.data[0].expiration_date;
          setAbonne(expirationDate);

          if (expirationDate) {
              const currentDate = moment();
              const endDate = moment(expirationDate);
              const daysLeft = endDate.diff(currentDate, 'days');
console.log("daysLeft",daysLeft)
              // Vérifiez si l'abonnement expirera dans les 7 jours
              if (daysLeft <= 7 && daysLeft > 0) {
                  setMessage(`Fin d'abonnement dans ${daysLeft} jour(s).`);
              }
              else if(daysLeft == 0){
                console.log("daysLeft condition",daysLeft)
                setMessage(`Fin d'abonnement aujourd'hui.`);
              }
          } else {
              setMessage("");
          }
      })
      .catch(err => console.log(err));
}, [id]); 



  return (
    <>
     

  
    <div className='wax'>
   
    <div className="ban2">
    
    
    <i className={currentAnnexIndex !== 0 ? 'fas fa-chevron-left arrow-icon' : "fas fa-chevron-left arrow-icon2" } onClick={handlePrevAnnex}></i>
    <div >

    <span className="llo">  AUTO ECOLE {lig}{nom} {lieu}</span>
     
{message && <div className="alertMM">{message}</div>} {/* Afficher le message d'alerte */} 
      </div>
    <i className={currentAnnexIndex === infoVille.length - 1 ? 'fas fa-chevron-right arrow-icon2' : 'fas fa-chevron-right arrow-icon'} onClick={handleNextAnnex}></i>
 
  
  </div>

      
      <div className='fil'>
        <div className='disEnd'>
          {input1} {input2}
           <button type='submit' onClick={handleSubmit} className='butonSF3D'>Appliquer</button>
           </div>
           </div>


           <div className="dashboard-container">
           <Link className='bnfs' to={'/candidatDir/'+id}> 
              <div className="dashboard-card statistiques-generales">
                <div className="card-title">INSCRITS</div>
                <div className="card-content">
                  {inscritTotal}
                </div>
              </div>
              </Link>
              <Link className='bnfs' to={'/ouiCandidatesPagesDir/'+id}>
              <div className="dashboard-card statistiques-candidats">
                <div className="card-title">ADMIS</div>
                <div className="card-content">
                {nbrAd}
                </div>
              </div>
              </Link>
              <Link className='bnfs' to={'/candidatesPagesDir/'+id}>
              <div className="dashboard-card statistiques-annexes">
                <div className="card-title">NON ADMIS</div>
                <div className="card-content">
            {nbrNad}
                </div>
              </div>
              </Link>
              <Link to={'/depenseDir/'+id} className='bnfs'>
              <div className="dashboard-card alertes-notifications">
                <div className="card-title">DEPENSES</div>
                <div className="card-content">
                  {depenseTotal+totalDep1} FCFA
                </div>
              </div>
              </Link>
              <Link to={'/payementDir/'+id} className='bnfs'>
              <div className="dashboard-card resume-activites-recentes">
                <div className="card-title">RECETTES</div>
                <div className="card-content">
                {paiementTotal} FCFA
                </div>
              </div>
              </Link>
              <Link className='bnfs' /* to={'/permisDir/'+id} */>
              <div className="dashboard-card resume-activites-suv">
                <div className="card-title">PERMIS RETIRÉ(S)</div>
                <div className="card-content">
               {permis}
                </div>
              </div>
              </Link>
              <br/>
            </div>


   
    <div className='graph'>
            <div className="cardDash">
          <h3 className='vost1'>Évolution Dynamique des Inscriptions de Candidats</h3>
          <div className="canvas-container">
            <canvas ref={chartRef}></canvas>
          </div>
        </div>
    
        <div className="cardDash">
          <h3 className='vost2'>Rapport financier</h3>
          <div className="canvas-container">
            <canvas ref={chartRef2}></canvas>
          </div>
        </div>
    
     </div>
    
           
     
            </div>
    
            </>
  );
}