import React, { useState, useEffect } from 'react';
import { AppBar, Tabs, Tab, Box, Card, CardContent, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import NoSuccess from './noSuccess';
import NoSuccessAll from './noSuccessAll';
import Navbar from '../components/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'; // Icône WhatsApp
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'; // Icône Info
import { API_BASE_URL } from '../apiConfig';
const CandidatesPage = () => {
    const [value, setValue] = useState(0);
    const [messageGeneral, setMessageGeneral] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [currentMessage, setCurrentMessage] = useState('');
    const [currentCandidate, setCurrentCandidate] = useState({});
    const [candidateCount, setCandidateCount] = useState(0);
    const [candidateCount2, setCandidateCount2] = useState(0);
    // Charger le message général enregistré dans le localStorage au chargement de la page
    useEffect(() => {
        const savedMessage = localStorage.getItem('messageGeneral');
        if (savedMessage) {
            setMessageGeneral(savedMessage);
        }
    }, []);

    // Mettre à jour le message dans le localStorage à chaque modification
    const handleMessageChange = (e) => {
        const newMessage = e.target.value;
        setMessageGeneral(newMessage);
        localStorage.setItem('messageGeneral', newMessage); // Met à jour localStorage
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Fonction pour personnaliser le message en fonction du sexe
    const generatePersonalizedMessage = (nom, prenom, sexe) => {
        let salutation = sexe === 'Masculin' ? 'Monsieur' : 'Madame'; // Choisir entre Monsieur ou Madame
        // Formule d'appel personnalisée
        const formuleAppel = `${salutation} ${prenom} ${nom},`;
        
        // Ajouter la formule d'appel au début du message général
        return `${formuleAppel}\n\n${messageGeneral}`; // Ajouter un saut de ligne après la formule
    };

    // Ouvrir la fenêtre modale pour éditer le message avant d'envoyer via WhatsApp
    const handleWhatsappClick = (contact, nom, prenom, sexe) => {
        if (!messageGeneral) {
            alert("Veuillez d'abord enregistrer un message général.");
            return;
        }
        if (contact.length === 10) {
            contact = contact.slice(2); // Supprimer les deux premiers chiffres si longueur = 10
          }
        // Créer un message personnalisé en fonction du sexe
        const personalizedMessage = generatePersonalizedMessage(nom, prenom, sexe);
        setCurrentMessage(personalizedMessage);
        setCurrentCandidate({ contact, nom, prenom, sexe });
        setOpenDialog(true);
    };

    const sendWhatsappMessage = () => {
        const encodedMessage = encodeURIComponent(currentMessage); // Encodage correct du message
        const url = `https://wa.me/${currentCandidate.contact}?text=${encodedMessage}`;
        window.open(url, '_blank');
        setOpenDialog(false);
    };

    return (
        <div>
            <Navbar />
            <Box 
                sx={{
                    width: { xs: '90%', sm: '80%', md: '70%' }, 
                    margin: '0 auto',
                    mt: 3
                }}
            >
                {/* Barre de navigation avec les onglets */}
                <AppBar 
                    position="static" 
                    sx={{ 
                        bgcolor: 'rgb(6, 122, 116)', 
                        '@media (max-width: 600px)': { padding: '10px 0' } 
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="nav tabs example"
                        textColor="inherit"
                        variant="fullWidth"
                    >
                          <Tab 
                            label={`Récents (${candidateCount})`} 
                            sx={{
                                fontWeight: value === 0 ? 'bold' : 'normal',
                                fontSize: { xs: '0.9rem', sm: '1.1rem' } 
                            }} 
                        />
                        <Tab 
                            label={`Tout (${candidateCount2})`} 
                            sx={{
                                fontWeight: value === 1 ? 'bold' : 'normal',
                                fontSize: { xs: '0.9rem', sm: '1.1rem' } 
                            }} 
                        />
                      
                    </Tabs>
                </AppBar>

                {/* Carte contenant les onglets et le message à éditer */}
                <Card sx={{ mt: 3, boxShadow: 3 }}>
                    <CardContent>
                        {/* Section pour éditer le message général */}
                        <div style={{ marginBottom: '20px' }}>
                            <h3>
                                Message Général à Envoyer :
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className="info-icon"
                                    style={{ marginLeft: '8px', cursor: 'pointer', color: '#007bff' }} // Couleur de l'icône
                                />
                            </h3>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                value={messageGeneral}
                                onChange={handleMessageChange} // Met à jour directement le localStorage
                                placeholder="Écrivez un message."
                                variant="outlined"
                                sx={{ marginBottom: '10px' }}
                            />
                        </div>

                        {/* Affichage des candidats selon l'onglet sélectionné */}
                        <TabPanel value={value} index={0}>
                            <NoSuccess onCandidateCount={setCandidateCount} onWhatsappClick={handleWhatsappClick} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <NoSuccessAll onCandidateCount={setCandidateCount2} onWhatsappClick={handleWhatsappClick} />
                        </TabPanel>
                       
                    </CardContent>
                </Card>

                {/* Fenêtre modale pour éditer le message avant envoi */}
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogTitle>Éditer le message avant envoi</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            value={currentMessage}
                            onChange={(e) => setCurrentMessage(e.target.value)}
                            variant="outlined"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDialog(false)} color="secondary">Annuler</Button>
                        <Button onClick={sendWhatsappMessage} color="primary">Envoyer sur WhatsApp</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </div>
    );
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

export default CandidatesPage;