import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { Link } from "react-router-dom";
import Loader from "../components/loader";
import Cookies from 'js-cookie';
import moni from '../components/image/moni.png';
import secre from '../components/image/images.png'
import ToggleSwitch2 from '../components/toggleSwitch2';
import { API_BASE_URL } from '../apiConfig';
import { NavLink, useNavigate,  useLocation } from "react-router-dom";
export default function SecretaireResultConduite(){

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
      const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const [candidat, setCandidat] = useState([])
    const [viewLoader,setViewLoader] = useState(false)
    const [titre,setTitre]= useState(Cookies.get('titre'))
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [loader, setLoader]= useState(true)
    const [error, setError] = useState(null);
    const [id, setId] = useState('')
    const [id2, setId2]= useState('')
    const [defaultV,setDefaultV]= useState(Cookies.get('default'))
   const [defaultV2,setDefaultV2]= useState(Cookies.get('default2'))
   const [select1,setSelect1]= useState(Cookies.get('select1'))
   const [select2,setSelect2]= useState(Cookies.get('select2'))
    useEffect(()=>{
            axios.post(API_BASE_URL +'/avoir',{gestauto,titre}, { headers})
            .then(res =>{
                 setId(res.data[0].id_ecole)
                 setId2(res.data[0].id_ecole)
                })
            .catch(err => console.log(err));
    
        }, [])
    
    

  var date= new Date();
  const now= date.toLocaleDateString();
 const years= date.getFullYear()
 const days= date.getDate()
var  month=  date.getMonth()+1
var autre= date.toISOString().split('T')[0] 
var datee= new Date(years,month-3,days)
var autre2= datee.toISOString().split('T')[0]
const [date1, setDate1]= useState(autre2)
    const [dat, setDat]= useState('')
  const [date2, setDate2]= useState(autre)

const [out, setOut]= useState('is')
    




const mettreAJourLargeurEcran = () => {
    setLargeurEcran(window.innerWidth);

  };



  useEffect(() => {       
    window.addEventListener('resize', mettreAJourLargeurEcran);  
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    }; 
  }, []);


  useEffect(() => {       
    if(select1=== undefined || select2=== undefined){
 
    }else{
    setDefaultV(select1)
    setDefaultV2(select2)
    }
   }, []);


  if(id!='' && out=='is'){
    if(defaultV || defaultV2){
        const date1= defaultV
        const date2= defaultV2
        axios.post(API_BASE_URL +'/condResultat',{date1,date2,id}, { headers})
        .then(res => {
                     
            setCandidat(res.data)
            setLoader(false)
        })
        .catch(err =>{
             console.log(err)
             setError(err)
             setLoader(false)
        
            });

        setId('')
        setOut('')
    }else{
        axios.post(API_BASE_URL +'/condResultat',{date1,date2,id}, { headers})
        .then(res => {
                     
            setCandidat(res.data)
            setLoader(false)
        })
        .catch(err =>{
             console.log(err)
             setError(err)
             setLoader(false)
        
            });

        setId('')
        setOut('')
    }
    }

    function handlePropose(event){
       
        setViewLoader(true)
        event.preventDefault();
        Cookies.set('default',date1)
        Cookies.set('default2',date2)
        Cookies.set('select1',date1)
        Cookies.set('select2',date2)
        axios.post(API_BASE_URL +'/modf2Date', {date1,date2,id2}, { headers})
        .then(res => {
           setCandidat(res.data)
          
           setViewLoader(false)
           setId('')
        }).catch(err =>{
             console.log(err)
             setError(err)
          
             setViewLoader(false)
        });
        
    }









    const [selectedCandidats, setSelectedCandidats] = useState([]); // Stocker les candidats sélectionnés
    const [isLoading, setIsLoading] = useState(false); // Gérer le loader pendant la mise à jour

    // Fonction pour sélectionner/désélectionner les candidats
    const handleSelectCandidat = (id) => {
        if (selectedCandidats.includes(id)) {
            // Si le candidat est déjà sélectionné, le retirer de la liste
            setSelectedCandidats(selectedCandidats.filter(candidatId => candidatId !== id));
        } else {
            // Sinon, ajouter le candidat à la liste des sélectionnés
            setSelectedCandidats([...selectedCandidats, id]);
        }
    };

    // Fonction pour mettre à jour les candidats sélectionnés avec le statut (true/false)
    const handleBatchUpdate = async (status) => {
        setIsLoading(true); 
    /*   setViewLoader(true) */
        try {
            const response = await axios.post(API_BASE_URL +'/batch-updateConduite', {
                candidats: selectedCandidats,
                status: status, // true pour "admis", false pour "non admis"
                autre,
            });

            console.log('Mise à jour réussie pour les candidats sélectionnés.');
            // Optionnel : Réinitialiser la sélection après mise à jour
          
        } catch (error) {
            console.error('Erreur lors de la mise à jour des candidats :', error);
        } finally {
            setTimeout(() => {
                /* setIsLoading(false);  */
                setSelectedCandidats([]);
                /* setViewLoader(false) */
                 setIsLoading(false) 
               
            }, 4000);
            window.location.reload()
        }
    };


















    const [secretaire, setSecretaire] = useState([])
 const navigate= useNavigate()
     useEffect(()=>{
        
        axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])
    
    const [schoolInfo, setSchoolInfo]= useState([])


    const [mie, setMie]= useState(Cookies.get('mie'))
    
            useEffect(()=>{
                if(secretaire.length>0){   
                    axios
                    .get(`${API_BASE_URL}/schoolInfo?id=${mie}`, { headers })
                    .then((res) => setSchoolInfo(res.data))
                    .catch((err) => console.error(err));
                }
             }, [secretaire])
            
    
      useEffect(()=>{
                if(schoolInfo.length>0){
                const currentDate = new Date();
          const expirationDate = new Date(schoolInfo[0].expiration_date);
          if(expirationDate<currentDate){
          navigate('/resultat')
        }
          }
             }, [schoolInfo])

  
     const [query, setQuery]= useState(""); 

     let load

     if(viewLoader==true){
        load=   <div className="avt">
        <div className="rin">
          <div className="chm">
            <Loader/>
          </div>
        </div>
      </div>
    }else{
        load=''
    }


     if(loader){
        return(
            <div className="per">
            
            <div className="loader">
            <div className="overlayLo"> 
                <div className="loaderP">
                <Loader/>
                </div>
                </div>
                </div>
                </div>
        )
    }else if(error){
        return (<div>Une erreur est survenue : {error.message}</div>)
    }else{
     if(largeurEcran>= 1024){
    return(
        <>
             
             <div className='wini'>
        <Navbar/>
        <h1 className='win'>La page de gestion des resultats conduite</h1><br/><br/>
        {load}
        <div className='aff'>
            
        <div className="search">
             <input type="text" className="recherche" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
        </div>
        <div className='person2'>
           <input type='date' defaultValue={defaultV ? defaultV : date1} className='oix2' onChange={e => setDate1(e.target.value)}></input> <input type='date' defaultValue={defaultV2 ? defaultV2 : date2}  className='oix2' onChange={e => setDate2(e.target.value)} ></input> 
           <button type='submit' onClick={handlePropose} className='somi2'>APPLIQUER</button>
           </div>
           </div>
           <br/><br/><br/>

    <div className=''>
    <div className="card-list">
                 {
                    
candidat
.length === 0 ? (
  <div className="message-erreur">
   Aucun candidat n'est éligible pour l'examen de conduite.
  </div>
) : (
                            candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                                <div 
                                className="card" 
                                onClick={() => handleSelectCandidat(data.id)}  // Ajoute onClick sur la carte
                               
                            >
                                <input 
                                    type="checkbox"
                                    className="wolf-checkbox"
                                    onChange={(event) => { 
                                        event.stopPropagation(); // Empêche le clic sur le checkbox de déclencher le clic sur la carte
                                        handleSelectCandidat(data.id); 
                                    }}
                                    checked={selectedCandidats.includes(data.id)} 
                                />
                                <div className="image-container8">
                                    <img 
                                        src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} 
                                        alt="Candidat" 
                                        className="profile-image" 
                                    />
                                </div>
                                
                                <div className='info'>
                                    <p className='name'> {data.nom} {data.prenom}</p>
                                    <p className={data.activeConduite === 1 ? 'wolfOui' : data.activeConduite === 0 ? 'wolfNon' : ''}>
                                        {data.activeConduite === 1 ? 'ADMIS' : data.activeConduite === 0 ? 'NON ADMIS' : ''}
                                    </p>
                                </div>
                            </div>
                        
                            ))
                        )
                          } 

{selectedCandidats.length > 0 && (
        <div className="wolf-action-bar">
           <p>
  {isLoading ? "Mise à jour en cours..." : `${selectedCandidats.length} candidats sélectionnés`}
</p>

            <div className="wolf-toggles">
                <label className="wolf-toggle-label">
                    Admis
                    <div className="wolf-toggle-switch">
                        <input 
                            type="checkbox" 
                            className="wolf-toggle-input" 
                            onChange={() => handleBatchUpdate(true)} 
                        />
                        <span className="wolf-slider wolf-admis"></span>
                    </div>
                </label>

                <label className="wolf-toggle-label">
                    Non Admis
                    <div className="wolf-toggle-switch">
                        <input 
                            type="checkbox" 
                            className="wolf-toggle-input" 
                            onChange={() => handleBatchUpdate(false)} 
                        />
                        <span className="wolf-slider wolf-non-admis"></span>
                    </div>
                </label>
            </div>
   
           
        </div>
    )}
                 </div>
    </div>
    <br/>
    </div>
    <Footer/>
        </>
    )}
    else if(largeurEcran>=510){
        return(
            <>
                 
                 <div className='wini'>
            <Navbar/>
            <h1 className='win'>La page de gestion des resultats conduite</h1><br/><br/>
          
{load}
        <div className='barContener'>

{/* Barre de recherche */}
<div className='barChercheT'>
    <input type="text"className='rechBar'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
</div>


{/* Barre de recherche personnalisée */}
<div className='persBarT'>
    
    <input type='date' className='perSF' defaultValue={defaultV ? defaultV : date1} onChange={e => setDate1(e.target.value)}></input>
    <input type='date' className='perSF'  defaultValue={defaultV2 ? defaultV2 : date2} onChange={e => setDate2(e.target.value)}></input>
    <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
</div>

</div>     <br/><br/><br/>
    
               <div className="card-list">
   
{

candidat
.length === 0 ? (
  <div className="message-erreur">
   Aucun candidat n'est éligible pour l'examen de conduite.
  </div>
) :(


                            candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                                <div 
                                className="card" 
                                onClick={() => handleSelectCandidat(data.id)}  // Ajoute onClick sur la carte
                               
                            >
                                <input 
                                    type="checkbox"
                                    className="wolf-checkbox"
                                    onChange={(event) => { 
                                        event.stopPropagation(); // Empêche le clic sur le checkbox de déclencher le clic sur la carte
                                        handleSelectCandidat(data.id); 
                                    }}
                                    checked={selectedCandidats.includes(data.id)} 
                                />
                                <div className="image-container8">
                                    <img 
                                        src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} 
                                        alt="Candidat" 
                                        className="profile-image" 
                                    />
                                </div>
                                
                                <div className='info'>
                                    <p className='name'> {data.nom} {data.prenom}</p>
                                    <p className={data.activeConduite === 1 ? 'wolfOui' : data.activeConduite === 0 ? 'wolfNon' : ''}>
                                        {data.activeConduite === 1 ? 'ADMIS' : data.activeConduite === 0 ? 'NON ADMIS' : ''}
                                    </p>
                                </div>
                            </div>
                        
                            ))
)
                          } 

{selectedCandidats.length > 0 && (
        <div className="wolf-action-bar">
           <p>
  {isLoading ? "Mise à jour en cours..." : `${selectedCandidats.length} candidats sélectionnés`}
</p>

            <div className="wolf-toggles">
                <label className="wolf-toggle-label">
                    Admis
                    <div className="wolf-toggle-switch">
                        <input 
                            type="checkbox" 
                            className="wolf-toggle-input" 
                            onChange={() => handleBatchUpdate(true)} 
                        />
                        <span className="wolf-slider wolf-admis"></span>
                    </div>
                </label>

                <label className="wolf-toggle-label">
                    Non Admis
                    <div className="wolf-toggle-switch">
                        <input 
                            type="checkbox" 
                            className="wolf-toggle-input" 
                            onChange={() => handleBatchUpdate(false)} 
                        />
                        <span className="wolf-slider wolf-non-admis"></span>
                    </div>
                </label>
            </div>
   
           
        </div>
    )}
                 </div>
        <br/>
        </div>
        <Footer/>
            </>
        )
    }
    else{
        return(
            <>
                 
                 <div className='wini'>
            <Navbar/>
            <h1 className='win'>La page de gestion des resultats conduite</h1><br/><br/>
          
{load}
        <div className='barContener'>

{/* Barre de recherche */}
<div className='barChercheT'>
    <input type="text"className='rechBar'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
</div>


{/* Barre de recherche personnalisée */}
<div className='persBarT'>
    
    <input type='date' className='perSF' defaultValue={defaultV ? defaultV : date1} onChange={e => setDate1(e.target.value)}></input>
    <input type='date' className='perSF'  defaultValue={defaultV2 ? defaultV2 : date2} onChange={e => setDate2(e.target.value)}></input>
    <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
</div>

</div>     <br/><br/><br/>
    
               <div className="card-list">
                 {


candidat
.length === 0 ? (
  <div className="message-erreur">
   Aucun candidat n'est éligible pour l'examen de conduite.
  </div>
) :(

                            candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                                <div 
                                className="card" 
                                onClick={() => handleSelectCandidat(data.id)}  // Ajoute onClick sur la carte
                               
                            >
                                <input 
                                    type="checkbox"
                                    className="wolf-checkbox"
                                    onChange={(event) => { 
                                        event.stopPropagation(); // Empêche le clic sur le checkbox de déclencher le clic sur la carte
                                        handleSelectCandidat(data.id); 
                                    }}
                                    checked={selectedCandidats.includes(data.id)} 
                                />
                                <div className="image-container8">
                                    <img 
                                        src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} 
                                        alt="Candidat" 
                                        className="profile-image" 
                                    />
                                </div>
                                
                                <div className='info'>
                                    <p className='name'> {data.nom} {data.prenom}</p>
                                    <p className={data.activeConduite === 1 ? 'wolfOui' : data.activeConduite === 0 ? 'wolfNon' : ''}>
                                        {data.activeConduite === 1 ? 'ADMIS' : data.activeConduite === 0 ? 'NON ADMIS' : ''}
                                    </p>
                                </div>
                            </div>
                        
                            ))
                        )
                          } 

{selectedCandidats.length > 0 && (
        <div className="wolf-action-bar">
           <p>
  {isLoading ? "Mise à jour en cours..." : `${selectedCandidats.length} candidats sélectionnés`}
</p>

            <div className="wolf-toggles">
                <label className="wolf-toggle-label">
                    Admis
                    <div className="wolf-toggle-switch">
                        <input 
                            type="checkbox" 
                            className="wolf-toggle-input" 
                            onChange={() => handleBatchUpdate(true)} 
                        />
                        <span className="wolf-slider wolf-admis"></span>
                    </div>
                </label>

                <label className="wolf-toggle-label">
                    Non Admis
                    <div className="wolf-toggle-switch">
                        <input 
                            type="checkbox" 
                            className="wolf-toggle-input" 
                            onChange={() => handleBatchUpdate(false)} 
                        />
                        <span className="wolf-slider wolf-non-admis"></span>
                    </div>
                </label>
            </div>
   
           
        </div>
    )}
                 </div>
        <br/>
        </div>
        <Footer/>
            </>
        )
    }
}
}