
import axios from "axios";
import React, {useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/loader";
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../apiConfig';
export default function CreatePayementDir() {
    const [name, setName] = useState('')
    const [prenom, setPrenom]= useState('')
    const [titre,setTitre]= useState(Cookies.get('titre'))
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
      const [error, setError] = useState(null);
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
const [ce,setCe]= useState(Cookies.get('autoa'))
    const [lapaye, setLapaye]= useState('')
    const [loader, setLoader]= useState(false);
    const [date, setDate]= useState('')
    const [libelle, setLibelle]= useState('')
    const [montant, setMontant]= useState('')
    const navigate = useNavigate();
    const [info, setInfo]= useState('')
    const [logaId, setLogaID]= useState(0)
    const [loga, setLoga]= useState('')
    const [dateControl, setDateControl]= useState('')
    const [annexe, setAnnexe]= useState('')
    const [sum,setSum]= useState('')
    const [idAnnexe,setIdAnnexe]= useState('')
    const [montant_payer, setMontant_payer]= useState('')
    const [montant_total, setMontant_Total]= useState('')
    const[lea,setLea]= useState([])
    const [secretaire, setSecretaire] = useState([])
   
    
 
    const  le = lea.map((data)=>(data.id_ecole))
   
    var o=le
    const {id}= useParams();
    if (o!=0){
        o=le
    }else{
        
        o=id
    }

   
         useEffect(()=>{
            axios.post(API_BASE_URL +'/idAnnexe', {o}, { headers})
            .then(res =>{ 
                
                setIdAnnexe(res.data[0].ville)             
            }
               
    
            ).catch(err => console.log(err));
        }, [])
            var b
   





    const [ido, setIdo]= useState('')
    useEffect(()=>{
        axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])



    const [ville, setVille] = useState([])
    useEffect(()=>{
        axios.get(API_BASE_URL +'/candidatDir?id='+ce+'' )
        .then(res =>{
             setVille(res.data)
             setLoga(res.data[0].ville)
             setLogaID(res.data[0].id_annexe)
             setIdo(res.data[0].id_ecole)
             if(annexe==''){
               setAnnexe( res.data[0].ville)
             }
            })
        .catch(err => console.log(err));
    }, [])

    var id_annexe=0;
      if(ville.map((data)=>(data.id_annexe))){
     [id_annexe]= ville.map((data)=>(data.id_annexe));
    }


    function firstSubmit(event){
        event.preventDefault();
if(id_annexe!=0){
        axios.post(API_BASE_URL +'/candidatDir', {annexe,id_annexe}, { headers})
    .then(res => {
        setLea(res.data)
        setIdo(res.data[0].id_ecole)

       


       

    }).catch(err => console.log(err));
    }
}
    
            
           if(idAnnexe!=''){
            b= idAnnexe
          }  else{
            b= loga
          }

     
 
 
    function handleSubmit(event){
        setInfo('')
        setLoader(true)
        setLapaye('')
        event.preventDefault();

        if( montant=="" || libelle=="" || date=="" || name=="" || prenom==""){
            setLoader(false)
            setInfo("UN CHAMP N'EST PAS REMPLI VEILLEZ VERIFIER!!!")
           
         }else if(montant<=0){
            setLoader(false)
            setInfo('VERIFIER LA VALEUR DU MONTANT')
         }
         else if(date>autre){
            setLoader(false)
            setDateControl('Verifier la date')
        } else{


            axios.post(API_BASE_URL +'/lapaye', {name,prenom}, { headers})
        .then(res => {
            
            if(res.data.message){
                setLoader(false)
                setLapaye(res.data.message);
                
                
            }
         else{
            setMontant_Total(res.data[0].montant_total)
            setMontant_payer(res.data[0].montant_payer)
            const id= res.data[0].id
            axios.post(API_BASE_URL +'/vefMontantDir', {montant,ido,id_annexe,id}, { headers})
            .then(res => {
         
                setSum(res.data[0].sum)
          
               
              
                if(sum+ montant_payer< montant_total){
                    axios.post(API_BASE_URL +'/createPayementDir', {name, prenom, date,libelle,montant,ido,id_annexe,id}, { headers})
                    .then(res => {
                        setLoader(false)
                        navigate('/payementDir/'+o);
                    }).catch(err => console.log(err));
                }else if(sum+montant_payer== montant_total){
                    setLoader(false)
                    setInfo('Le candidat a soldé')

                    }
                
                
            }).catch(err => console.log(err));
        }
    }).catch(err => console.log(err));


}  
}

const handleGoBack = () => {
    navigate('/payementDir/'+o);
  };




  const [schoolInfo, setSchoolInfo]= useState([])




        useEffect(()=>{
            if(secretaire.length>0){   
         axios.get(API_BASE_URL +'/schoolInfoDir?id='+secretaire[0].id_autoa+'', { headers })
         .then(res => setSchoolInfo(res.data))
         .catch(err => console.log(err));
         }
         }, [secretaire])
         




         useEffect(()=>{
            if(schoolInfo.length>0){
            const currentDate = new Date();
      const expirationDate = new Date(schoolInfo[0].expiration_date);
      if(expirationDate<currentDate){
      navigate('/payementDir/'+o)
    }
      }
         }, [schoolInfo])
    
    var date2= new Date();
         const now= date2.toLocaleDateString();
         
         
   
        var autre= date2.toISOString().split('T')[0]  
        useEffect(()=>{
            setDate(autre)
        }, [])

        let load

        if(loader==true){
            load=   <div className="modal">
                <div className="overlay">
    
                </div>
                   <div className="modal-contentis">
        <div className="anous">
        <Loader/>
        </div>
        </div>
            </div>
           
        }else{
            load=''
        }
    
    return(
        
    <div className="auths">
        
         
        <body className="body">
         
         <div className="container">
         <FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
                 <form action="#" onSubmit={handleSubmit}>
                 
                 
                 <select name="poste" className='sele' onChange={e => setAnnexe(e.target.value)}>
            <option >{b}</option>
                     
                {  
                    ville.filter((data) => data.ville!=b).map((data)=>(   
                        
                        <option value={data.ville}>{data.ville}</option>
                            
                    ))
                    
                }
           </select>   

           <button className="btn" onClick={firstSubmit}> APPLIQUER </button>

                 <div className="main-user-info">
             
            

        
           
                 <div className="user-input-box">
                  <label htmlFor="fullname" className="">Nom</label>
                    
                    <input type="text" name="fullName" className="" id="fullName" onChange={e => setName(e.target.value)}  ></input>
                   
                    </div> 
                  
                 <div className="user-input-box">
                 <label htmlFor="username" className="">Prenom</label>
                    <input type="text" name="fullName" className="" id="fullName" onChange={e => setPrenom(e.target.value)}></input>
                    
                    </div>   
                    
                    <div className="user-input-box">
                    <label htmlFor="date" className="">DATE</label>
                    <h4 className="text-danger">{dateControl}</h4>
                    <input type="date" value={date} name="date" className=""  id="date" onChange={e => setDate(e.target.value)}  ></input>
                   
                    </div>
                    
                    <div className="user-input-box">
                    <label htmlFor="montant" className="">Montant</label>
                    <input type="number" name="montant" className="" id="montant" onChange={e => setMontant(e.target.value)}></input>
                    
                    </div>
                    <div className="libe">
                    <label htmlFor="libelle" className="">Libelle</label>
                    <input type="text" name="libelle" className="" id="libelle" maxLength={100} onChange={e => setLibelle(e.target.value)}></input>
                 
                    </div>
                    </div>
                    <h3 className="text-danger">{lapaye}</h3><br/>
                    <h4 className="text-danger">{info}</h4>
                    
                    <div className="form-submit-btn">
                    <input type="submit" className='' value="ENREGISTRER" ></input>
                    </div>
                   
                    
                 </form>
            </div> 
             <br/><br/><br/>
             </body>
             {load}
    </div>
    )
}