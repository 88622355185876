import Navbar from '../components/navbar';
import { Link } from "react-router-dom";
import Footer from '../components/footer';
import Loader from "../components/loader";
import React,{ useEffect, useState} from 'react';
import Cookies from 'js-cookie'
import { API_BASE_URL } from '../apiConfig';
import axios from 'axios';
import Notification from '../components/notifications';
export default function Presence() {






  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
    'credentials': 'include',
  };


const [mie, setMie]= useState(Cookies.get('mie'))


const [schoolInfo, setSchoolInfo] = useState([]);

useEffect(() => {
  if (mie) {
    axios
      .get(`${API_BASE_URL}/schoolInfo?id=${mie}`, { headers })
      .then((res) => setSchoolInfo(res.data))
      .catch((err) => console.error(err));
  }
}, [mie, headers]);



const [showNotification, setShowNotification] = useState(false);

      const isDateExpired = () => {
       const currentDate = new Date();
       const expirationDate = new Date(schoolInfo[0].expiration_date);
       return expirationDate < currentDate; // Retourne true si la date est dépassée
     };
 
     const handleExpire = (e) => {
     /*   if (isDateExpired()) {
         e.preventDefault(); // Empêche la redirection
         alert("La date d'expiration est dépassée. Veuillez renouveler votre abonnement pour ajouter un candidat.");    
       } */
 
         if (isDateExpired()) {
            e.preventDefault(); // Empêche la redirection
           setShowNotification(true); // Affiche le pop-up
           setTimeout(() => setShowNotification(false), 5000); // Cache le pop-up après 5 secondes
         }
 
     };





  
    return(
      <>  
    <div className="wini">
        <Navbar/>
         <h1 className='win'>Gestion des présences</h1>
         <div className='ope'>
         <Notification
        description="Veuillez renouveler votre abonnement pour acceder à cette fonctionnalité."
        show={showNotification}
      />
         <Link to={'/cond'}  onClick={handleExpire}>     <button className='pre'> PRESENCE CONDUITE</button></Link>
         <Link to={'/code'}  onClick={handleExpire}>       <button  className='cod'>PRESENCE CODE</button></Link>
                                    </div>
                                    <br/>                                
    </div>
    <Footer/>
    </>
    )
}