import React from 'react';


const Notification = ({  description, show }) => {
  if (!show) return null;

  return (
    <div className="notification">
      <div className="notification-header">
        <span className="notification-icon">⚠️</span>
        <span className="notification-title">Date d'expiration dépassée.</span>
      </div>
      <div className="notification-description">{description}</div>
    </div>
  );
};

export default Notification;
