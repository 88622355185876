import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../apiConfig';
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
    'credentials': 'include',
  };

const ProtectedLink = ({ idEcole, children, to , description}) => {
  const [schoolInfo, setSchoolInfo] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();

  // Fonction pour vérifier si la date est expirée
  const isDateExpired = () => {
    const currentDate = new Date();
    const expirationDate = new Date(schoolInfo[0]?.expiration_date); // Vérifie si l'information existe
    return expirationDate < currentDate; // Retourne true si la date est dépassée
  };

  // Récupération des informations de l'école
  useEffect(() => {
    if (idEcole) {
      axios
        .get(`${API_BASE_URL}/schoolInfo?id=${idEcole}`, { headers })
        .then((res) => setSchoolInfo(res.data))
        .catch((err) => console.error(err));
    }
  }, [idEcole, API_BASE_URL, headers]);

  // Gestion du clic sur le bouton
  const handleClick = (e) => {
    e.preventDefault(); // Empêche la redirection temporairement
    if (isDateExpired()) {
      setShowNotification(true); // Affiche le pop-up
      setTimeout(() => setShowNotification(false), 5000); // Cache le pop-up après 5 secondes
    } else {
      navigate(to); // Redirige vers la page si la date n'est pas expirée
    }
  };

  return (
    <>
      {showNotification && (
        <div className="notification">
          <div className="notification-header">
            <span className="notification-icon">⚠️</span>
            <span className="notification-title">Date d'expiration dépassée.</span>
          </div>
          <div className="notification-description">
          {description}
          </div>
        </div>
      )}
      <div className='yello'>
      <button onClick={handleClick} className="ajouter">
        {children}
      </button></div>
    </>
  );
};

export default ProtectedLink;
