import Navbar from '../components/navbar';
import { Link } from "react-router-dom";
import Footer from '../components/footer';
import Loader from "../components/loader";
import ProtectedLink from '../components/protectedLink';
import Cookies from 'js-cookie'
import React,{ useEffect, useState} from 'react';
import { API_BASE_URL } from '../apiConfig';
import Notification from '../components/notifications';
import axios from 'axios';
export default function Resultat(){


    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };


    const [mie, setMie]= useState(Cookies.get('mie'))


    const [schoolInfo, setSchoolInfo] = useState([]);

    useEffect(() => {
      if (mie) {
        axios
          .get(`${API_BASE_URL}/schoolInfo?id=${mie}`, { headers })
          .then((res) => setSchoolInfo(res.data))
          .catch((err) => console.error(err));
      }
    }, [mie, API_BASE_URL, headers]);



const [showNotification, setShowNotification] = useState(false);

          const isDateExpired = () => {
           const currentDate = new Date();
           const expirationDate = new Date(schoolInfo[0].expiration_date);
           return expirationDate < currentDate; // Retourne true si la date est dépassée
         };
     
         const handleExpire = (e) => {
         /*   if (isDateExpired()) {
             e.preventDefault(); // Empêche la redirection
             alert("La date d'expiration est dépassée. Veuillez renouveler votre abonnement pour ajouter un candidat.");    
           } */
     
             if (isDateExpired()) {
                e.preventDefault(); // Empêche la redirection
               setShowNotification(true); // Affiche le pop-up
               setTimeout(() => setShowNotification(false), 5000); // Cache le pop-up après 5 secondes
             }
     
         };








    return(
        <>
    <div className='wini'>
        <Navbar/>
      
     <div className='buto'>

     <Notification
        description="Veuillez renouveler votre abonnement pour acceder à cette fonctionnalité."
        show={showNotification}
      />
      <Link to={'/secretaireResultConduite'} onClick={handleExpire} > <button className='rec'> RESULTAT CONDUITE</button></Link>
    
        <Link to={'/secretaireResultCode'} onClick={handleExpire}>   <button  className='rev'>RESULTAT CODE</button></Link>
        

        </div>
        <div className='buto'>
        
        <Link to={'/oldCandidat'} onClick={handleExpire}> <button className='rea'> ANCIENS CANDIDATS</button></Link>
        </div>
        <br/>  
    </div>
    <Footer/>
    </>
    )
}