import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Loader from "../components/loader";
import * as FaIcons from "react-icons/fa";
import Toast from "../components/toast";
import ldsbg from './ldsbg.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../apiConfig';
function AuthV(){



    const [name, setName]= useState('')
    const [password, setPassword]= useState('')
    
    const [loginStatut, setLoginStatut]= useState('')
    
    const navigate = useNavigate();
    const [loader, setLoader]= useState(false);
   
    const [showToast, setShowToast] = useState(false);
      const [showToast2, setShowToast2] = useState(false);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    
    
    
      function handleSubmit(event){
       setLoader(true)
        event.preventDefault();

        if(name=== '' || password ===''){
            setLoader(false)
            setLoginStatut("Le username ou le password n'est pas rempli");
            setShowToast(true);
        }else{
        
        if(name.substring(0, 2)== "DG" || name.substring(0, 2)== "Dg" || name.substring(0, 2)== "dg" ){
            const choix= 'directeur'
   if(choix=='directeur'){
    //start
axios.post(API_BASE_URL +'/auth', {name,password,choix}, { headers})
.then(res => {

 if(res.data.message!= ''){

 if(res.data.message == "Mauvais username ou mot de passe"){
    setLoader(false)
     setLoginStatut(res.data.message);
     setShowToast(true);
 }/* else if(res.data.message === "Votre abonnement a expiré. Veuillez le renouveler."){
    setLoader(false)

    setShowToast2(true);
 } */
 
 else{
     const id= res.data.message;
     const token= res.data.message2
     const autoa= res.data.message3;
     console.log('id de mise', id, autoa)
     if(id && token){
     axios.post(API_BASE_URL +'/token', {choix,id}, { headers})
     .then(res => {
    
         if(res.data.message== 'new'){
             axios.post(API_BASE_URL +'/insert', {choix,token,id}, { headers})
             .then(res => {
                 if(res.data.message== 'err'){
                     console.log('pb cookIN')
                     
                 }else {
                    Cookies.set('token', token, { expires: 7 })
                    Cookies.set('gestauto', id, { expires: 7 })
                    Cookies.set('titre', choix, { expires: 7 })
                    Cookies.set('autoa', autoa, { expires: 7 })
                    setLoader(false)
                         navigate('/accueil2/'+0)
                                    
                 }
             }).catch(err => console.log(err));                  
         }else if(res.data.message== 'exist'){
             axios.put(API_BASE_URL +'/tokenUp/'+id, {choix,token,id}, { headers})
             .then(res => {                  
                 if(res.data.message== 'err'){
                     console.log('pb cookUp')                     
                 }else {
                    Cookies.set('token', token, { expires: 7 })
                    Cookies.set('gestauto', id, { expires: 7 })
                    Cookies.set('titre', choix, { expires: 7 })
                    Cookies.set('autoa', autoa, { expires: 7 })
                    setLoader(false)
                         navigate('/accueil2/'+0)
                   
                 }
             }).catch(err => console.log(err));
         }
     }).catch(err => console.log(err));       
 }else{
    console.log('err with message 1 or 2')
    setLoader(false)
 }
}

}

}).catch(err => console.log(err));

//end

   }

 }else if(name.substring(0, 10)== "Secretaire" || name.substring(0, 10)== "secretaire"){
     const choix='secretaire'
     if(choix=='secretaire'){
 //start secretaire
 axios.post(API_BASE_URL +'/auth', {name,password,choix}, { headers})
 .then(res => {
 
  if(res.data.message!= ''){

  if(res.data.message == "Mauvais username ou mot de passe"){
    setLoader(false)
      setLoginStatut(res.data.message);
      setShowToast(true);
  }
  /* else if(res.data.message === "Votre abonnement a expiré. Veuillez le renouveler."){
    setLoader(false)

    setShowToast2(true);
 } */
  else{
      const id= res.data.message;
      const token= res.data.message2;
      const mie= res.data.message3;
      console.log('Cookies reçus :', document.cookie);
      console.log('les variables :', id, token,mie);
      console.log('Valeur at variable :', res.data.message, res.data.message2,res.data.message3);
     if(id && token ){
      axios.post(API_BASE_URL +'/token', {choix,id}, { headers})
      .then(res => {
     
          if(res.data.message== 'new'){
              axios.post(API_BASE_URL +'/insert', {choix,token,id}, { headers})
              .then(res => {
                  if(res.data.message== 'err'){
                      console.log('pb cookIN')
                      
                  }else {
                    Cookies.set('token', token, { expires: 7 })
                    Cookies.set('gestauto', id, { expires: 7 })
                    Cookies.set('titre', choix, { expires: 7 })
                    Cookies.set('mie', mie, { expires: 7 })
                    setLoader(false)
                          navigate('/accueil');
                          setLoginStatut("Succes")
                                    
                  }
              }).catch(err => console.log(err));                  
          }else if(res.data.message== 'exist'){
            
      console.log('La verité:', res.data.message);
              axios.put(API_BASE_URL +'/tokenUp/'+id, {choix,token,id}, { headers})
              .then(res => {                  
                  if(res.data.message== 'err'){
                      console.log('pb cookUp')                     
                  }else {
                    Cookies.set('token', token, { expires: 7 })
                    Cookies.set('gestauto', id, { expires: 7 })
                    Cookies.set('titre', choix, { expires: 7 })
                    Cookies.set('mie', mie, { expires: 7 })
                    setLoader(false)
                          navigate('/accueil');
                          setLoginStatut("Succes")
                      
                    
                     
                  }
              }).catch(err => console.log(err));
          }
      }).catch(err => console.log(err));       
  }else{
    setLoader(false)
    console.log("err with message 1 or 2")
}
}

 }
 
 }).catch(err => console.log(err));
      //end secretaire
     }
    
 }else if(name.substring(0, 8)== "Moniteur" || name.substring(0, 8)== "moniteur"){
     const choix='moniteur'
     if(choix=='moniteur'){
        //start moniteur
     axios.post(API_BASE_URL +'/auth', {name,password,choix}, { headers})
     .then(res => {
     
      if(res.data.message!= ''){
     
      
      if(res.data.message == "Mauvais username ou mot de passe"){
        setLoader(false)
          setLoginStatut(res.data.message);
          setShowToast(true);
      }
      /* else if(res.data.message === "Votre abonnement a expiré. Veuillez le renouveler."){
        setLoader(false)
        setLoginStatut(res.data.message);
        setShowToast2(true);
     } */
      else{
          const id= res.data.message;
          const token= res.data.message2
          const mie= res.data.message3;
          if(id && token){
          axios.post(API_BASE_URL +'/token', {choix,id}, { headers})
          .then(res => {
         
              if(res.data.message== 'new'){
                  axios.post(API_BASE_URL +'/insert', {choix,token,id}, { headers})
                  .then(res => {
                      if(res.data.message== 'err'){
                          console.log('pb cookIN')
                          
                      }else {
                        Cookies.set('token', token, { expires: 7 })
                        Cookies.set('gestauto', id, { expires: 7 })
                        Cookies.set('titre', choix, { expires: 7 })
                        Cookies.set('mie', mie, { expires: 7 })
                        setLoader(false)
                              navigate('/accueil');
                                       
                      }
                  }).catch(err => console.log(err));                  
              }else if(res.data.message== 'exist'){
                  axios.put(API_BASE_URL +'/tokenUp/'+id, {choix,token,id}, { headers})
                  .then(res => {                  
                      if(res.data.message== 'err'){
                          console.log('pb cookUp')                     
                      }else {
                        Cookies.set('token', token, { expires: 7 })
                        Cookies.set('gestauto', id, { expires: 7 })
                        Cookies.set('titre', choix, { expires: 7 })
                        Cookies.set('mie', mie, { expires: 7 })
                        setLoader(false)
                              navigate('/accueil');
                          
                          
                      }
                  }).catch(err => console.log(err));
              }
          }).catch(err => console.log(err));       
      }else{
        console.log('err with message1 or 2')
      }
    }
    
     }
     
     }).catch(err => console.log(err));
          //end moniteur
     }
 }else {
     const choix='candidat'

if(choix=='candidat'){
         //start candidat
         axios.post(API_BASE_URL +'/auth', {name,password,choix}, { headers})
         .then(res => {
         
          if(res.data.message!= ''){
      
          if(res.data.message == "Mauvais username ou mot de passe"){
            setLoader(false)
              setLoginStatut(res.data.message);
              setShowToast(true);
          }
          else if(res.data.message === "Votre abonnement a expiré. Veuillez le renouveler."){
            setLoader(false)
            setLoginStatut(res.data.message);
            setShowToast2(true);
         }
          
          else{
              const id= res.data.message;
              const token= res.data.message2
              const mie= res.data.message3;
              if(id && token){
              axios.post(API_BASE_URL +'/token', {choix,id}, { headers})
              .then(res => {
             
                  if(res.data.message== 'new'){
                      axios.post(API_BASE_URL +'/insert', {choix,token,id}, { headers})
                      .then(res => {
                          if(res.data.message== 'err'){
                              console.log('pb cookIN')
                              
                          }else {
                            Cookies.set('token', token, { expires: 7 })
                            Cookies.set('gestauto', id, { expires: 7 })
                            Cookies.set('titre', choix, { expires: 7 })
                            Cookies.set('mie', mie, { expires: 7 }) 
                              console.log('connexion success')
                              setLoader(false)
                                  navigate('/pageCandidatCand/'+id);
                                            
                          }
                      }).catch(err => console.log(err));                  
                  }else if(res.data.message== 'exist'){
                      axios.put(API_BASE_URL +'/tokenUp/'+id, {choix,token,id}, { headers})
                      .then(res => {                  
                          if(res.data.message== 'err'){
                              console.log('pb cookUp')                     
                          }else {
                            Cookies.set('token', token, { expires: 7 })
                            Cookies.set('gestauto', id, { expires: 7 })
                            Cookies.set('titre', choix, { expires: 7 })
                            Cookies.set('mie', mie, { expires: 7 })
                            setLoader(false)
                                 console.log('connexion success')
                                  navigate('/pageCandidatCand/'+id);
                            
                          }
                      }).catch(err => console.log(err));
                  }
              }).catch(err => console.log(err));       
          }else{
            setLoader(false)
            console.log('err with message1 or 2')
          }
        }
   
         }
         
         }).catch(err => console.log(err));
              //end candidat
}
     
 }


        }

    

      }


      const [showPassword, setShowPassword] = useState(false);


      const togglePasswordVisibility = () => {
          setShowPassword(!showPassword);
      };


let load

if(loader==true){
    load=   <div className="per">

    <div className="loader">
    <div className="overlayLo"> 
        <div className="loaderP">
        <Loader/>
        </div>
        </div>
        </div>
        </div>
}else{
    load=''
}






    return(
    
<div>
    <div className="containerV">
        
   
        <div className="forms-containerV">


            
             <div className="signin">
                <form className="sign-in-form" onSubmit={handleSubmit}>
                
                    <h2 className="titleV">Connexion</h2>
                    {showToast && (
        <Toast message="Nom d'utilisateur ou mot de passe incorrect. Veuillez réessayer." onClose={() => setShowToast(false)} duration={3000}/>
      )}
      {showToast2 && (
        <Toast message="Votre abonnement a expiré. Veuillez le renouveler." onClose={() => setShowToast2(false)} duration={3000}/>
      )}
                    <div className="input-field">
                        <div className="ic">
                        <FaIcons.FaUser />
                        </div>
                        <input type="text" className="inputV" name="nom"  id="nom" onChange={e => setName(e.target.value)} placeholder="Nom d'utilisateur"/> 
                    </div>

                    <div className="input-field10"> 
                        <div className="ic10">
                      <FaIcons.FaLock />
                      </div>
                    
                        <input  type={showPassword ? 'text' : 'password'}  className="inputV"  name="password" id="password" onChange={e => setPassword(e.target.value)} placeholder="Mot de passe"/> 
                        <span className="toggle-password" onClick={togglePasswordVisibility}>
        <i className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
    </span>
                    </div>
                    <input type="submit" value='Se connecter'  className="btn solid"/>
                    
                </form>
             </div>
        </div>
        <div className="panels-containersV">
            <div className="panel left-panel">
               
                <img src={ldsbg} className='imoge'/>
            </div>
        </div>
    </div>
  {load}


</div>

    )
}export default AuthV

/*
 <div className="contentV">
                    
                </div>
                */