import React, { useState, useEffect } from 'react';
import { AppBar, Tabs, Tab, Box, Card, CardContent, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import NoSuccessDir from './noSuccessDir';
import NoSuccessAllDir from './noSuccessAllDir';
import Navbar from '../components/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'; // Icône WhatsApp
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'; // Icône Info

import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Link,NavLink,  useLocation} from "react-router-dom";
import moni from '../components/image/moni.png';
import log from '../components/image/log.png';
import Loader from "../components/loader";
import Cookies from 'js-cookie';
import { sidedataDir } from '../components/sidedataDir'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import { API_BASE_URL } from '../apiConfig';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import logo from '../components/image/logo4.jpg'
const CandidatesPageDir = () => {

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };


    const [value, setValue] = useState(0);
    const [messageGeneral, setMessageGeneral] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [currentMessage, setCurrentMessage] = useState('');
    const [currentCandidate, setCurrentCandidate] = useState({});


    const [error, setError] = useState(null);
    const [sidebar, setSidebar]= useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const [lu,setLu] = useState(Cookies.get('token'))
    const [tok, setTok]= useState('')
    const [titre,setTitre]= useState(Cookies.get('titre'))
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [old, setOld]= useState('')
    const [recup, setRecup]= useState('')
    const [new1, setNew1]= useState('')
    const [new2, setNew2]= useState('')
    const [info1, setInfo1]= useState('')
    const [info2, setInfo2]= useState('')

    const [candidateCount, setCandidateCount] = useState(0);
    const [candidateCount2, setCandidateCount2] = useState(0);
    
    const[profil, setProfil]= useState(false)
    
    const showProfil = () => setProfil(!profil)

    const [observation, setObservation]= useState('')
    const [poste, setPoste]= useState('')
    const {id}= useParams();
   
    





    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const mettreAJourLargeurEcran = () => {
        setLargeurEcran(window.innerWidth);
    
      };

      if(tok!= ''){
        if(tok!= lu){
            Cookies.remove('token')
            Cookies.remove('autoa')
            Cookies.remove('titre')
            Cookies.remove('gestauto')
            window.location.reload();
            navigate('/')

        }
    }

    var navigate= useNavigate()
    useEffect(()=>{
         if(!Cookies.get('token')){
            navigate('/')
         }
    }, [])


   
    
    const logout = () =>{
        Cookies.remove('token')
        Cookies.remove('autoa')
        Cookies.remove('titre')
        Cookies.remove('gestauto')
        Cookies.remove('default')
        Cookies.remove('default2')
        Cookies.remove('defaultCode')
        Cookies.remove('defaultCode2')
        window.location.reload();
       navigate('/')
    }
    useEffect(()=>{
        if(!Cookies.get('token')){
           navigate('/')
        }
   }, [])





useEffect(() => {
        
    window.addEventListener('resize', mettreAJourLargeurEcran);

   
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    };
   
  }, []);
// Recuperer token
useEffect(()=>{
    

axios.post(API_BASE_URL +'/setToken', { gestauto, titre}, { headers})
    .then(res => 
        {setTok(res.data[0].token)
        

        })
    .catch(err => console.log(err));
}, [])



const [secretaire, setSecretaire] = useState([])
useEffect(()=>{
    axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'', { headers})
    .then(res => {
      setSecretaire(res.data)
  })
    .catch(err => console.log(err));
}, []) 










const[valid, setValid]= useState('')
              
const[valid2, setValid2]= useState('')
const[supMod, setSupMod]= useState(false) 

const toggleSup = ()=> {
  
 
  setSupMod(!supMod)
  setValid('')
 
}


if(valid!= ''){
 toggleSup()
 


} 



              

const[supMod2, setSupMod2]= useState(false) 

const toggleSup2 = ()=> {
  
 
  setSupMod2(!supMod2)
  setValid2('')
  
}


if(valid2!= ''){
 toggleSup2()
 


} 




function handleModf(event){
    const id= gestauto
    event.preventDefault();
    const currentPassword = secretaire.map((data) => data.password)[0];
    
    if(currentPassword===old){
    
        if(new1===new2){
            setInfo2('')
            axios.put(API_BASE_URL +'/upPassword/'+id, {new1,titre}, { headers})
            .then(res => {
                  
        axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
                toggleSup()

            setValid2('on')
            }).catch(err => console.log(err));
        }else{
           
            setInfo2('Incorrecte')
            setTimeout(() => setInfo2(''), 3000)
        }
    }else{
        setInfo1('Incorrecte')
        setTimeout(() => setInfo1(''), 3000)
    }
}


const [menuVisible, setMenuVisible] = useState(false);

const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  
};




const [showOldPassword, setShowOldPassword] = useState(false);
const [showNewPassword, setShowNewPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);


const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);



useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuVisible && !document.querySelector('.carD').contains(event.target)) {
            setMenuVisible(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [menuVisible]);









 // ici bar
 var bar 
 const location = useLocation();
 if(largeurEcran>= 1200){
   bar=   <div className="barHaut">
<div className="lep">
           
           
           <img src={moni} className="user" onClick={showProfil} />

  
           
           <div className="hero"> 
            <h3 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h3>
            
           </div>

           <div class="options">
<h2 className="form-title2"> {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>           
<button class="option-btn" onClick={e => setValid('on')}>Modifier</button>
<button class="option-btn2" onClick={logout}>Déconnexion</button>
</div>

           </div>

   <NavLink to={'/accueil2/'+id} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Accueil</button> </NavLink>
   <NavLink to={'/candidatDir/'+id} className='dirResult'><button className='pr'> Candidats</button> </NavLink>
   <NavLink to={'/depenseDir/'+id} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Depenses</button> </NavLink>
   
   
   <NavLink to={'/payementDir/'+id} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Recettes</button> </NavLink>
   <NavLink to={'/bilan/'+id} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Bilan Global</button> </NavLink>
   <NavLink to={'/optPresenceDir/'+id} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Presence</button> </NavLink>
   <NavLink to={'/resultatDir/'+id} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Resultats</button> </NavLink>
   <Link onClick={logout} className="link">
<img src={log} className="am" />
<div className="deco">DECONNEXION</div>

</Link> 
   
     
   
   </div>
   
   
 }else{
   bar=  <IconContext.Provider value={{color:'#fff'}} >
                
   <div className="icon">
<div>
&nbsp;&nbsp;&nbsp;
<img src={logo} className="userLog"  /> 
</div>
      
<div className="naI">
      {secretaire.map((data)=>(data.auto_ecole))} 
    </div>   
<div className="navbar">

   <Link to="#" className={sidebar?  "trois": "menu-bars"}>
       <FaIcons.FaBars onClick={showSidebar} />
   </Link>

   &nbsp;&nbsp;&nbsp;
     

</div>

<nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
<ul className="nav-menu-items" >
<li className="navbar-toggle">
   <Link to="#" className="menu-bars">
      <AiIcons.AiOutlineClose onClick={showSidebar}/>
   </Link>
</li>
<div className="carD">
           <div className="profil">
<AiIcons.AiOutlineUser className="icone-profil"   onClick={toggleMenu} />
<div className="infos-utilisateur">
  <p className="inPo"   onClick={toggleMenu}> {secretaire.map((data)=>(data.poste))} </p>
 <p className="inN"  onClick={toggleMenu}> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
</div>
</div>



</div>
{sidedataDir.map((item, index) =>{
   const isActive = location.pathname.startsWith(item.path);
 return (
  
   <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
        <Link to={item.path+'/'+id}>
           <span>{item.title}</span>
        </Link>
       
   </li>
   
 
 )

})}
<li className="nav-text">
<Link onClick={logout}  >
    
      <span>Deconnexion</span>
      
 </Link>
 </li> 
</ul>
</nav>
</div>




</IconContext.Provider>
        
}






















    

    // Charger le message général enregistré dans le localStorage au chargement de la page
    useEffect(() => {
        const savedMessage = localStorage.getItem('messageGeneral');
        if (savedMessage) {
            setMessageGeneral(savedMessage);
        }
    }, []);

    // Mettre à jour le message dans le localStorage à chaque modification
    const handleMessageChange = (e) => {
        const newMessage = e.target.value;
        setMessageGeneral(newMessage);
        localStorage.setItem('messageGeneral', newMessage); // Met à jour localStorage
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Fonction pour personnaliser le message en fonction du sexe
    const generatePersonalizedMessage = (nom, prenom, sexe) => {
        let salutation = sexe === 'Masculin' ? 'Monsieur' : 'Madame'; // Choisir entre Monsieur ou Madame
        // Formule d'appel personnalisée
        const formuleAppel = `${salutation} ${prenom} ${nom},`;
        
        // Ajouter la formule d'appel au début du message général
        return `${formuleAppel}\n\n${messageGeneral}`; // Ajouter un saut de ligne après la formule
    };

    // Ouvrir la fenêtre modale pour éditer le message avant d'envoyer via WhatsApp
    const handleWhatsappClick = (contact, nom, prenom, sexe) => {
        if (!messageGeneral) {
            alert("Veuillez d'abord enregistrer un message général.");
            return;
        }

        if (contact.length === 10) {
            contact = contact.slice(2); // Supprimer les deux premiers chiffres si longueur = 10
          }

        // Créer un message personnalisé en fonction du sexe
        const personalizedMessage = generatePersonalizedMessage(nom, prenom, sexe);
        setCurrentMessage(personalizedMessage);
        setCurrentCandidate({ contact, nom, prenom, sexe });
        setOpenDialog(true);
    };

    const sendWhatsappMessage = () => {
        const encodedMessage = encodeURIComponent(currentMessage); // Encodage correct du message
        const url = `https://wa.me/${currentCandidate.contact}?text=${encodedMessage}`;
        window.open(url, '_blank');
        setOpenDialog(false);
    };

    return (
        <div>
            {bar}
            <Box 
                sx={{
                    width: { xs: '90%', sm: '80%', md: '70%' }, 
                    margin: '0 auto',
                    mt: 3
                }}
            >
                {/* Barre de navigation avec les onglets */}
                <AppBar 
                    position="static" 
                    sx={{ 
                        bgcolor: 'rgb(6, 122, 116)', 
                        '@media (max-width: 600px)': { padding: '10px 0' } 
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="nav tabs example"
                        textColor="inherit"
                        variant="fullWidth"
                    >

<Tab 
                            label={`Récents (${candidateCount})`} 
                            sx={{
                                fontWeight: value === 0 ? 'bold' : 'normal',
                                fontSize: { xs: '0.9rem', sm: '1.1rem' } 
                            }} 
                        />
                        <Tab 
                            label={`Tout (${candidateCount2})`}
                            sx={{
                                fontWeight: value === 1 ? 'bold' : 'normal',
                                fontSize: { xs: '0.9rem', sm: '1.1rem' } 
                            }} 
                        />
                      
                    </Tabs>
                </AppBar>

                {/* Carte contenant les onglets et le message à éditer */}
                <Card sx={{ mt: 3, boxShadow: 3 }}>
                    <CardContent>
                        {/* Section pour éditer le message général */}
                        <div style={{ marginBottom: '20px' }}>
                            <h3>
                                Message Général à Envoyer :
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className="info-icon"
                                    style={{ marginLeft: '8px', cursor: 'pointer', color: '#007bff' }} // Couleur de l'icône
                                />
                            </h3>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                value={messageGeneral}
                                onChange={handleMessageChange} // Met à jour directement le localStorage
                                placeholder="Écrivez un message."
                                variant="outlined"
                                sx={{ marginBottom: '10px' }}
                            />
                        </div>

                        {/* Affichage des candidats selon l'onglet sélectionné */}
                        <TabPanel value={value} index={0}>
                            <NoSuccessDir onCandidateCount={setCandidateCount} onWhatsappClick={handleWhatsappClick} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <NoSuccessAllDir onCandidateCount={setCandidateCount2} onWhatsappClick={handleWhatsappClick} />
                        </TabPanel>
                      
                    </CardContent>
                </Card>

                {/* Fenêtre modale pour éditer le message avant envoi */}
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogTitle>Éditer le message avant envoi</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            value={currentMessage}
                            onChange={(e) => setCurrentMessage(e.target.value)}
                            variant="outlined"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDialog(false)} color="secondary">Annuler</Button>
                        <Button onClick={sendWhatsappMessage} color="primary">Envoyer sur WhatsApp</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </div>
    );
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

export default CandidatesPageDir;