import axios from "axios";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import {useParams} from "react-router-dom";
import Cookies from 'js-cookie';
import { API_BASE_URL } from '../apiConfig';
const OuiSuccessDir = ({onCandidateCount, onWhatsappClick }) => { // Ajout de la prop onWhatsappClick
    const date = new Date();
    const years = date.getFullYear();
    const month = date.getMonth() + 1;
    const days = date.getDate();
    const datee = new Date(years, month - 2, days);
    const [lesNdm, setLesNdm] = useState([]);
  
    const [select1, setSelect1] = useState(Cookies.get('select1'));
    const [select2, setSelect2] = useState(Cookies.get('select2'));
 
    const [mie, setMie]= useState(Cookies.get('olaf'));
    const [id, setId] = useState('');
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    
    const autre2 = datee.toISOString().split('T')[0];
    const autre = date.toISOString().split('T')[0];

    const [date1, setDate1] = useState('');
    const [date2, setDate2] = useState('');

   

    useEffect(() => {
        setLoader(true); 

        if (select1 === undefined || select2 === undefined) {
            setDate1(autre2);
            setDate2(autre);
        } else {
            setDate1(select1);
            setDate2(select2);
        }
    }, []);

    useEffect(() => {

        
if(date1 !== '' && date2 !== '' && mie){
    console.log('Appel API avec:', { date1, date2 });
        axios.post(API_BASE_URL +'/yesSuccess', { date1, date2, mie })
            .then(res => {
                console.log('Réponse de l\'API:', res.data);
                setLesNdm(res.data);
                setLoader(false);
                onCandidateCount(res.data.length);
            })
            .catch(err => {
                console.log(err);
                setError(err);
                setLoader(false);
            });
        }
    }, [mie,date1,date2]);

    return (
        <div className="">
            <div className="modal-corps">
                {loader ? (
                    <p>Chargement...</p>
                ) : lesNdm.length === 0 ? (
                    <p>Aucun candidat admis.</p>
                ) :   (
                    <div className="success-liste-candidats">
                        {lesNdm.reverse().map((data, i) => (
                            <div className="success-card" key={i}>
                                <div className="success-card-content">
                                    <div className="success-info">
                                        <h3>{data.nom} {data.prenom}</h3>
                                    </div>
                                    <div className="success-icons">
                                        <a href={`tel:${data.contact.length === 8 ? "01"+ data.contact   : data.contact}`} className="success-icon">
                                            <FontAwesomeIcon icon={faPhone} />
                                        </a>

                                        <a
                                            onClick={() => onWhatsappClick(data.contact, data.nom, data.prenom,data.sexe)} // Utilisation de la fonction onWhatsappClick
                                            className="success-icon"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <FontAwesomeIcon icon={faWhatsapp} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {error && <p className="error">Erreur : {error.message}</p>}
            </div>
        </div>
    );
};

export default OuiSuccessDir;
